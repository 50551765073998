/*
 * @Author: 关关
 * @Date: 2023-11-10 14:23:00
 * @LastEditors: your name
 * @LastEditTime: 2024-01-11 16:11:35
 * @Description:
 * @FilePath: \biaoshishanxi\src\main.ts
 */
import { Component, ComputedOptions, MethodOptions, createApp } from "vue";

import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
// import '@/assets/Js/flexible.js'
import "element-plus/dist/index.css";
// import vue3videoPlay from 'vue3-video-play' // 引入组件
// import 'vue3-video-play/dist/style.css' // 引入css
import App from "./App.vue";
import axios from "axios";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
// Vue.use(vueAplayer)
// const app = createApp(App);
// 如果您正在使用CDN引入，请删除下面DD一行。
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
// app.config.globalProperties.qs = qs
import VueLuckyCanvas from '@lucky-canvas/vue'
// 在main.js中全局引入影像
// import AudioVisual from 'vue-audio-visual'
import VideoPlayer from "vue-video-player/src";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";

// import "element-plus/theme-chalk/index.css"
import "element-plus/dist/index.css";
import locale from "element-plus/es/locale/lang/zh-cn";
import SvgIcon from "@/components/SvgIcon/index.vue";
import "@/assets/icons/index";
const app = createApp(App);
app.directive('download', {
  // 在绑定时执行
  beforeMount(el, binding) {
    // 添加点击事件监听器
    el.addEventListener('click', () => {
      const link = document.createElement('a');
      link.href = binding.value;
      link.download = '';
      link.target = '_blank'; // 在某些浏览器中需要添加此属性才能触发下载
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
});

app.use(router);
app.use(store);
app.use(VueLuckyCanvas);
app.use(ElementPlus, { locale });
app.use(VideoPlayer);
app.component("svg-icon", SvgIcon);
app.config.globalProperties.axios = axios;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component as Component<any, any, any, ComputedOptions, MethodOptions>);
}
app.mount("#app");
