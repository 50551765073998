import axios from "axios";
const { CancelToken } = axios;
import qs from "qs";
import SparkMD5 from "spark-md5";
import { ElMessage } from "element-plus";
// import  {useRouter} from "vue-router";
import router from "@/router/index";
/* *********************************************** */
/**
 * @param {Object} type 类型[git ,post]  默认 git
 * @param {Object} url  url 必填
 * @param {Object} isLoginBoor  检测登录token  默认false
 * @param {Object} funThen 请求成功函数
 * @param {Object} funError 请求失败函数
 *
 */

import { serverBaseUrl } from "./ls_tgServerUrls.js";
let cancel;
let hasShownWarning = false;
export const Axios = function (
  type,
  url,
  params,
  isLoginBoor,
  funThen,
  funError,
  type1
) {
  // const router = new useRouter();

  isLoginBoor = isLoginBoor || false;
  if (url == "" || !url) {
  } else {
    let title = "";
    if (params) {
      const flattenParams = (obj, prefix = "") => {
        let queryString = "";
        for (const key in obj) {
          if (typeof obj[key] === "object") {
            queryString += flattenParams(
              obj[key],
              prefix ? `${prefix}[${key}]` : key
            );
          } else if (Array.isArray(obj[key])) {
            obj[key].forEach((value, index) => {
              queryString += `${prefix ? `${prefix}[${key}]` : key
                }[${index}]=${encodeURIComponent(value)}&`;
            });
          } else {
            queryString += `${prefix ? `${prefix}[${key}]` : key
              }=${encodeURIComponent(obj[key])}&`;
          }
        }
        return queryString;
      };

      title += flattenParams(params);
      // Remove the trailing '&' character if present
      title = title.replace(/&$/, "");
    }
    // console.log(title);
    if (type == "post") {
      if (typeof params == "string") {
        params = params.toString();
      } else {
        params = JSON.stringify(params);
      }
    }

    axios({
      method: type || "get",
      url:
        type == "GET" || type == "get"
          ? serverBaseUrl + url + "?" + title
          : serverBaseUrl + url,
      headers: {
        Accept: "*/*",
        "Content-Type":
          type1 == 1 ? "multipart/form-data;" : "application/json",
        Authorization:
          localStorage.getItem("token") && type1 != 1
            ? "Bearer " + localStorage.getItem("token")
            : "",
        "Access-Control-Allow-Origin": "*",
      },
      CancelToken: new CancelToken(function executor(c) {
        // Set the cancel function
        // cancel = c;
      }),
      data: type == "GET" || type == "get" ? "" : params || [],
    }).then((res) => {
      // console.log(res)
      /* 处理登录状态 */
      // if (isLoginBoor) {
      // 	if (!localStorage.getItem('token')) {
      // 		// 返回登录页面
      // 	}
      // }
      //正常处理
      if (funThen && res.data.code == "200") {
        funThen(res);

      } else {
        /* 
         401提醒用户登录
         */
        // 状态码特殊处理
        if (res.data.code == "401") {
          localStorage.removeItem("token");
          if (cancel) {
            cancel();
            cancel = null; // 置空 cancel 变量
          }
          if (!hasShownWarning) {
            hasShownWarning = true;
            ElMessage({
              showClose: true,
              message: "请登录后再次操作",
              type: "warning",
            });
            router.push({
              path: "/TgLogin",
            });
            //写一个一秒执行函数
            setTimeout(() => {
              hasShownWarning = false;
            }, 10000);
          }
          return;
        }
        // 其他状态逻辑操作
        if (funError) {
          funError(res);
        } else {
          ElMessage({
            showClose: true,
            message: res.data.msg,
            type: "warning",
          });
        }
      }
    });
  }
};

/*
 * fn [function] 需要防抖的函数
 * interval [number] 毫秒，防抖期限值
 * 在第一次触发事件时，不立即执行函数，而是给出一个期限值比如300ms
 * 让某个时间期限（300毫秒）内，事件处理函数只执行一次。
 */
// export const  debounce = function(fn, interval) {
//      var timeout = null;//借助闭包
//      return function (e) {
//          clearTimeout(timeout);//进入该分支语句，说明当前正在一个计时过程中，并且又触发了相同事件。所以要取消当前的计时，重新开始计时
//          timeout = setTimeout(() => { // 进入该分支说明当前并没有在计时，那么就开始一个计时
//              fn.apply(this, arguments);
//          }, interval ? interval : 300);
//      };
//  },

/**
 * @description: 文件加密处理
 */
// const getMD5 = function(file1, start, end) {

// 	return new Promise((resolve, reject) => {
// 		let file = file1[0].raw // 其中的raw才是File对象
// 		const spark = new SparkMD5.ArrayBuffer();
// 		let blobSlice =
// 			File.prototype.slice ||
// 			File.prototype.mozSlice ||
// 			File.prototype.webkitSlice;
// 		const fileReader = new FileReader();
// 		// fileReader.readAsArrayBuffer(file); // file 就是获取到的文件
// 		fileReader.readAsArrayBuffer(blobSlice.call(file, start, end)); // file 就是获取到的文件
// 		fileReader.addEventListener('load', (e) => {
// 			spark.append(e.target.result);
// 			const md5 = spark.end();
// 		});
// 	});

// }

// let getMD5 = function(file , start , end) {
// 	return new Promise((resolve, reject) => {
// 		let blobSlice =
// 			File.prototype.slice ||
// 			File.prototype.mozSlice ||
// 			File.prototype.webkitSlice;
// 		let chunks = Math.ceil(file.size / 200)
// 		let currentChunk = 0;
// 		let spark = new SparkMD5.ArrayBuffer();
// 		let fileReader = new FileReader();
// 		fileReader.onload = function(e) {
// 			spark.append(e.target.result);
// 			currentChunk++;
// 			if (currentChunk < chunks) {
// 				loadNext();
// 			} else {
// 				let _md5 = spark.end();
// 				resolve(_md5);
// 			}
// 		};

// 		function loadNext() {
// 			console.log(file)
// 			let slice = file.slice(start, end);
// 			fileReader.readAsArrayBuffer(slice); // 这样才可以正常运行
// 		}
// 		loadNext();
// 	});
// }
/**
 * @description: 文件加密处理
 */
const getMD5 = function (file) {
  new Promise((resolve, reject) => {
    const spark = new SparkMD5.ArrayBuffer();
    // 获取文件二进制数据
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    // fileReader.readAsArrayBuffer(file); // file 就是获取到的文件
    // 异步执行函数
    fileReader.addEventListener("load", (e) => {
      spark.append(e.target.result);
      const md5 = spark.end();
      resolve(md5);
    });
    fileReader.addEventListener("error", (e) => {
      reject(e);
    });
  });
};
/* 切片函数 */
/**
 * @description: 上传文件 - 切片
 * @param file 文件
 * @param shardIndex 当前要传的切片下标  默认0 
 * @param uploadFile  上传切片函数 直接Axios('类型' , 'url' ...)
 * @param composeFile 文件合并函数 [返回合并是否完成] // 

 */

export const uploadFileSilce = async (
  file,
  shardIndex,
  uploadFile,
  composeFile
) => {
  console.log(file);
  console.log("================================");
  // 文件名
  const { name } = file;
  // 文件大小
  const { size } = file;
  // 分片大小
  const shardSize = 1024 * 1024 * 5;
  // 分片总数
  const shardTotal = Math.ceil(size / shardSize);
  // 文件开始结束的位置
  const start = shardIndex * shardSize;
  const end = Math.min(start + shardSize, size);
  // 文件加密
  const hash = await getMD5(file, shardIndex, shardSize);
  console.log(hash);
  console.log("------hash");

  // 如果 当前分片索引 大于 总分片数
  if (shardIndex >= shardTotal) {
    isAlive.value = false;
    progress.value = 100;
    /* 这里放后端合并接口的文件 */
    composeFile(name, hash);
    return;
  }

  // 开始切割
  // console.log(file)
  // console.log("=-0=-=-=-=")
  // var aBlob = new Blob(file);
  // const packet = file.slice(start, end);

  // 拼接请求参数
  const formData = new FormData();
  formData.append("file", file);
  formData.append("applicationId", props.applicationId);
  formData.append("applicationVersion", props.applicationVersion);
  formData.append("bucketName", "app");
  formData.append("hash", hash);
  formData.append("shardSize", shardSize);
  formData.append("seq", shardIndex);

  // 如果 当前分片索引 小于 总分片数
  if (shardIndex < shardTotal) {
    // 进度条保留两位小数展示
    progress.value = Number(((shardIndex / shardTotal) * 100).toFixed(2)) * 1;
    Axios(axiosType, axiosUrl, axiosData, false, axiosFunstion);
    // 调用文件上传接口
    const res = await uploadFile(formData);
    if (res.status !== 200) {
      ElMessage.error("上传失败");
      progress.value = 0;
      return;
    }
    if (res.status === 200 && res.data.code === 200) {
      // 这里为所有切片上传成功后进行的操作
      console.log("上传成功");
    }
    // eslint-disable-next-line no-param-reassign
    shardIndex++;
    // 递归调用 分片函数
    uploadFileSilce(file, shardIndex, uploadFile, composeFile);
  }
};
/**
 * @description: 合并文件
 * @param name 文件名
 * @param hash 文件唯一 hash 值
 * @return 命名名称
 */
const composeFile = async (name, hash) => {
  console.log("开始文件合并");
  const res = await uploadFileMerge({
    applicationId: props.applicationId,
    applicationVersion: props.applicationVersion,
    bucketName: "app",
    fileName: name,
    hash,
  });
  console.log("后端接口合并文件 ===", res);
  if (res.status === 200 && res.data.code) {
    // 合并成功后，调整已上传的文件名称
    state.editForm.inlineAppVersionModel.fileName = name;
  }
};
// 公共获取登录后收藏的数据
// export const getCollectList = function () {
//   Axios(
//     "get",
//     "/fodder/info/" + newData.message.id,
//     false,
//     false,
//     function (res) {
//       list.value = res.data.data;
//       newData.message = res.data.data;
//     }
//   );
// };
