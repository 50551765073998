// 引入
import axios from "axios";

/* ****************************************************************************************** */
/* *******************************************基础封装*************************************** */
/* ****************************************************************************************** */
let com_con_type = true; // 日志开关 主要用于日志统一的关闭和开启, 防止再项目中有一些日志没有清理干净
/* 日志封装 */
let com_sole = (function () {
  /* log 打印*/
  function log(index) {
    congather(index, "log");
  }
  /* 错误打印 */
  function error(index) {
    congather(index, "error");
  }
  /* 提示打印 */
  function inof(index) {
    congather(index, "inof");
  }
  /* 警告打印 */
  function warn(index) {
    congather(index, "warn");
  }
  /* 打印嵌套 [默认打开] */
  function group(index) {
    congather(index, "group");
  }
  /* 打印嵌套 [默认关闭] */
  function groupColl(index) {
    congather(index, "groupCollapsed");
  }
  /* 表格打印 */
  function table(index) {
    congather(index, "table");
  }
  /* 追踪函数过程 */
  function tracer(index) {
    congather(index, "tracer");
  }
  /* 动态获取方法和输出类型 */
  function congather(index, type) {
    if (com_con_type) {
      eval("console." + type + "(" + JSON.stringify(index) + ")");
    }
  }
  return {
    /*console打印集合 */
    log: log,
    error: error,
    inof: inof,
    warn: warn,
    group: group,
    groupColl: groupColl,
    table: table,
    tracer: tracer,
  };
})();
/* 类型判断 */
let com_isType = (function () {
  // 是否是字符串
  function isString(value) {
    return Object.prototype.toString.call(value) == "[object String]";
  }
  // 是否是数字
  function isNumber(value) {
    return Object.prototype.toString.call(value) == "[object Number]";
  }
  // 是否是布尔值
  function isBoolean(value) {
    return Object.prototype.toString.call(value) == "[object Boolean]";
  }
  // 是否undefined
  function isUndefined(value) {
    return Object.prototype.toString.call(value) == "[object Undefined]";
  }
  // 是否是null
  function isNull(value) {
    return Object.prototype.toString.call(value) == "[object Null]";
  }
  // 是否数组
  function isArray(value) {
    return Object.prototype.toString.call(value) == "[object Array]";
  }
  // 是否是函数
  function isFunction(value) {
    return Object.prototype.toString.call(value) == "[object Function]";
  }
  // 是否是对象
  function isObject(value) {
    return Object.prototype.toString.call(value) == "[object Object]";
  }
  // 是否是正则表达式
  function isRegExp(value) {
    return Object.prototype.toString.call(value) == "[object RegExp]";
  }
  // 是否是日期对象
  function isDate(value) {
    return Object.prototype.toString.call(value) == "[object Date]";
  }
  return {
    isString: isString,
    isNumber: isNumber,
    isBoolean: isBoolean,
    isUndefined: isUndefined,
    isNull: isNull,
    isArray: isArray,
    isFunction: isFunction,
    isObject: isObject,
    isRegExp: isRegExp,
    isDate: isDate,
  };
})();
/* set 待完善 */
let com_set = (function () {
  function setMerge(...value) {
    let arr = new Set();
    for (let i = 0; i < value.length; i++) {
      if (isArray(value[i])) {
        for (let j = 0; j < value[i].length; j++) {
          arr.add(value[i][j]);
        }
      } else {
        // congather('数据类型错误 setMerge', 'error');
        return "[数据类型错误 setMerge]";
      }
    }
    return arr;
  }
  /* set 多个数组的交集 [相同部分集合] */
  function setOwn(...value) {
    let returnValue = new Set(value[0]);
    for (let i = 0; i < value.length; i++) {
      let returnValue1 = new Set(value[i]);
      let returnValue2 = new Set(value[i + 1]);
      if (i != 0) {
        returnValue = new Set([...returnValue].filter((x) => value[i].has(x)));
      }
    }
    return returnValue;
  }
  /* set 两个数组的差集 [不同部分] 有问题 */
  function setUnlike(value1, value2) {
    return new Set([...value1].filter((x) => !value2.has(x)));
  }
  return {
    setMerge: setMerge,
    setOwn: setOwn,
    setUnlike: setUnlike,
  };
})();

/* localStorage 缓存 */
let com_localStroage = (function () {
  /* 添加缓存 */
  function set_localStroage(key, value) {
    if (key && value) {
      window.localStorage.setItem(key, value);
    } else {
      com_sole.congather("必填字段不可为空 [local_set]", "error");
    }
  }
  /* 获取指定缓存 */
  function get_localStroage(key) {
    if (key) {
      if (window.localStorage.getItem(key)) {
        return window.localStorage.getItem(key);
      } else {
        com_sole.congather("该缓存不存在 [local_get]", "inof");
        return null;
      }
    } else {
      com_sole.congather("必填字段不可为空 [local_get]", "error");
    }
  }
  /* 获取所有缓存 */
  function all_localStroage() {
    return localStorage.valueOf();
  }
  /* 删除某个key缓存 */
  function remove_localStroage(key) {
    if (key) {
      localSXtorage.removeItem(key);
    } else {
      com_sole.congather("必填字段不可为空 [local_ermove]", "error");
    }
  }
  /* 清空缓存 */
  function clear_localStroage() {
    localStorage.clear();
  }
  /* 判断key缓存是否存在 */
  function has_localStroage(key) {
    if (key) {
      return localStorage.hasOwnProperty(key);
    } else {
      com_sole.congather("必填字段不可为空 [local_has]", "error");
    }
  }

  return {
    set_localStroage: set_localStroage,
    get_localStroage: get_localStroage,
    all_localStroage: all_localStroage,
    remove_localStroage: remove_localStroage,
    clear_localStroage: clear_localStroage,
    has_localStroage: has_localStroage,
  };
})();
/* Date  */
let com_date = (function () {
  /* 获取当前时间 */
  /* 
	 isTimer 布尔值 true 年月日 + 时分秒  false 只有年月日
	 separatorFront : 年月日的分割线 默认 "-" 
	 separatorDehind : 时分秒的分割线 默认 ":" 
	 */
  function set_newDate(isTimer, separatorFront, separatorDehind) {
    let t = new Date();
    let time = "";
    let n = t.getFullYear();
    let y = isZero(t.getMonth() + 1);
    let r = isZero(t.getDate());
    let s = isZero(t.getHours());
    let f = isZero(t.getMinutes());
    let m = isZero(t.getSeconds());
    let front = separatorFront ?? "-";
    let behind = separatorDehind ?? ":";
    let timer = isTimer == null || isBoolean(isTimer) ? true : false;
    if (!timer) {
      time = `${n}${front}${y}${front}${r}`;
    } else {
      time = `${n}${front}${y}${front}${r} ${s}${behind}${f}${behind}${m}`;
    }
    return time;
  }
  /* 时间戳转字符串 */
  /*
	 timestamp 时间戳 默认当前时间 
	 isTimer 布尔值 true 年月日 + 时分秒  false 只有年月日
	 separatorFront : 年月日的分割线 默认 "-" 
	 separatorDehind : 时分秒的分割线 默认 ":" 
	 */
  function timestamp_string(
    timestamp,
    isTimer,
    separatorFront,
    separatorDehind
  ) {
    let time = "";
    let t = "";
    if (timestamp) {
      t = new Date(timestamp);
    } else {
      t = new Date();
    }
    let n = t.getFullYear();
    let y = isZero(t.getMonth() + 1);
    let r = isZero(t.getDate());
    let s = isZero(t.getHours());
    let f = isZero(t.getMinutes());
    let m = isZero(t.getSeconds());
    let front = separatorFront ?? "-";
    let behind = separatorDehind ?? ":";
    // let timer = isTimer == null || isBoolean(isTimer) ? true : false;
    // let timer = isTimer;
    // isTimer 判断是整日还是 有小时
    if (!isTimer) {
      time = `${n}${front}${y}${front}${r}`;
    } else {
      time = `${n}${front}${y}${front}${r} ${s}${behind}${f}${behind}${m}`;
    }
    return time;
  }
  // 加“0”判断
  function isZero(s) {
    s = s < 10 ? "0" + s : s;
    return s;
  }

  return {
    set_newDate: set_newDate,
    timestamp_string: timestamp_string,
  };
})();
export default {
  com_sole: com_sole,
  com_isType: com_isType,
  com_localStroage: com_localStroage,
  com_date: com_date,
};
