<template>
  <div class="floor">
    <div class="floor_top">
      <img class="floor_top_logo" src="../../assets/herder_logo.png" />
      <div class="floor_top_wzdh">
        <div class="floor_top_wzdh_title">网址导航</div>
        <div class="floor_top_wzdh_list">
          <span class="cur" v-for="   index    in    navList   " @click.stop="navClick(index.url)" :key="index">{{
            index.title
          }}</span>
        </div>
      </div>
      <div class="floor_top_fwrx">
        <div class="floor_top_fwrx_title">联系客服</div>
        <div class="floor_top_fwrx_list">
          <img src="../../assets/floor_youxiang.png" />
          <span>biaoshishanxi@163.com</span>
          <img src="../../assets/floor_shouji.png" />
          <span>15343512201</span>
        </div>
      </div>
      <div class="floor_top_fwrx">
        <div class="floor_top_fwrx_title">关于我们</div>
        <div class="floor_top_fwrx_list">
          <span style="cursor: pointer;" @click="openagreement('sfdlsqxy')">用户协议</span>
          <span style="cursor: pointer;" @click="$router.push('/aboutnr')">关于我们</span>
        </div>
      </div>
    </div>
    <div style="
        width: 100%;
        padding: 0.09rem 0;
        background: #f8f9fa;
        font-size: 0.11rem;
        text-align: center;
        color: #333;
      ">
      © 2023 ByteDance -werereal　<span class="open" @click="openopen">晋ICP备2022003644号</span>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
export default {
  setup(props, context) {
    const router = new useRouter();
    const route = new useRoute();
    const navList = ref([
      {
        url: "/",
        title: "首页",
        class: "header_nav_title header_nav_title_y",
      },
      {
        url: '/ShadowIndexAllWorks',
        title: '找案例',
        class: 'header_nav_title header_nav_title_n'
      },
      {
        url: "/ShadowComposer",
        title: "找创作人",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowMaterial",
        title: "找素材",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowBusiness",
        title: "找业务",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowEquipment",
        title: "找器材",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowTrain",
        title: "培训",
        class: "header_nav_title header_nav_title_n",
      },
      {
        url: "/ShadowActivity",
        title: "活动",
        class: "header_nav_title header_nav_title_n",
      },
    ]);
    const navClick = function (url) {
      if (url != '/ShadowMaterial') {
        router.push({
          path: url,
        });
      } else {
        router.push({
          path: url,
          query: {
            type: 'video'
          }
        });
      }
      // context.emit("NavUrlFun", url);
    };
    onMounted(() => {
      // let a = document.querySelector(".floor");
      // (route.path == "/ShowActivity") ? a.classList.add("p") : a.classList.remove("p");
    });
    watch(route, (newVal, oldVal) => {
      // let a = document.querySelector(".floor");
      // (newVal.path == "/ShowActivity") ? a.classList.add("p") : a.classList.remove("p");
    })
    const openopen = () => {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_bank')
    }
    const openagreement = (val) => {
      window.open(`/agreement?name=${val}`, "_bank");
    };
    return {
      router,
      navList,
      navClick,
      openopen,
      openagreement
    }
  }
}

</script>

<style lang="less">
.p {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 9999 !important;
}

.floor {
  padding: 0 0.19rem;
  background: #fff;
  border-top: 0.01rem solid #e5e6eb;
  z-index: 1;
  width: 100%;
}

.floor_top {
  width: 12.8rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.floor_top>.floor_top_logo {
  width: 1.11rem;
  margin-right: 0.5rem;
}

.floor_top_wzdh,
.floor_top_fwrx {
  margin-right: 0.76rem;
}

.floor_top_wzdh_title,
.floor_top_fwrx_title {
  font-size: 0.13rem;
  cursor: pointer;
}

.floor_top_wzdh_list,
.floor_top_fwrx_list {
  margin-top: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.11rem;
}

.floor_top_wzdh_list>span {
  margin-right: 14px;

}

.floor_top_fwrx_list span {
  margin-right: 0.24rem;
}

.floor_top_fwrx_list img {
  width: 0.23rem;
  margin-right: 0.08rem;

}

.open {
  cursor: pointer;
}

.open:hover {
  text-decoration: underline;
}

@media screen and (max-width: 750px) {
  .floor {
    width: 100vw;
  }

  .floor_top {
    width: 100%;
    display: block;
  }

  .floor_top_wzdh_title {
    font-size: 16px;
    margin-top: 24px;
    font-weight: bold;
  }

  .floor_top_wzdh_list {
    display: block;
  }

  .floor_top_wzdh_list>span {
    font-size: 12px;
    margin-right: 14px;
    font-weight: bold;
  }

  .floor_top_wzdh_list,
  .floor_top_fwrx_list {
    margin-top: 12px;
  }

  .floor_top_fwrx {
    width: 100%;
  }

  .floor_top_fwrx_title {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin-top: 24px;
  }

  .floor_top_fwrx {
    display: none;
  }
}
</style>
