// version:20200709。 系统配置
const appConfig = {
	// 关于
	contactWebsite: "", // 联系网址
	contactTel: "", //  联系电话
	contactEmail: "", //  联系邮箱
	contactAddress: "", // 联系地址
	contactName: "", // 联系名称
	// 基本配置
	appName: "", // 项目名字
	appLogTag: "", // log tag
	enableDebug: true, // 设置是否打开调试开关。此开关对正式版也能生效。
	welcomeDelayTime: 5, // 欢迎界面延迟时间
	tokenExpireTime: 60 * 60 * 1000 * 12, // token 过期时长 12个小时
	// server 配置
	serverProtocal: 'http', // server 协议
	// serverIp: '192.168.0.11', // server IP
	serverIp: '//bs.vvqc.cn', // server IP
	serverPort: '13002', // server 端口
	serverName: null ,// server 项目名称 ，一般不写，设置为null
	// 
}
export default {
	appConfig
}
