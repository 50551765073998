<template>
  <div class="tgLogin" style="
      overflow: hidden;
      display: block;
      position: relative;
      min-height: 100vh;
      min-width:100vw;
      overflow-x: clip;
    ">
    <!-- <div
      @click="tgLoginClick"
      style="
        width: 100%;
        height: 100%;
        position: relative;
      "
    ></div> -->
    <img style="
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      " src="../../assets/logoBG.png" />
    <!-- 微信扫码登录 -->

    <img class="img_logo" src="../../assets/logoBaise.png" />
    <div class="img_logosize">
      为您提供一站式数字影像解决方案
    </div>
    <div class="logi_bor">
      <!-- 二维码 -->
      <div class="TgWXSM" v-if="moduleID == 2">
        <!-- <div class="TgWXSM_open" @click="tgLoginClick">
        <img src="../../assets/module_share_open.png" />
      </div> -->
        <p class="TgWXSM_title1">微信扫码登录</p>
        <!-- <p class="TgWXSM_title2">未注册的微信号将自动创建标识山西账号</p> -->

        <div class="rwm_body">
          <!--  -->
          <div v-if="codeUrl == ''">
            <img src="../../assets/jzzIcon.png" />
            <span>加载中</span>
          </div>
          <img style="width: 100%" v-if="codeUrl != ''" :src="codeUrl" />
        </div>
        <p style="
            color: #333;
            fon-size: 0.12rem;
            text-align: center;
            margin-top: 0.12rem;
          ">
          <img style="width: 0.16rem; height: 0.16remas; margin-right: 0.06rem" src="../../assets/login_wx.png" />微信扫码
        </p>
        <button class="accountButton" @click="moduleIDClick(1)">
          <img src="../../assets/login_sj.png" />账号注册登录
        </button>
      </div>
      <!-- 账号注册登录 -->
      <div class="TgWXSM" v-if="moduleID == 1">
        <!-- <div class="TgWXSM_open" @click="tgLoginClick">
        <img src="../../assets/module_share_open.png" />
      </div> -->
        <div v-loading="loadingRegister">
          <div style="text-align: center">
            <span style="" v-for="index in cutList" :key="index"
              :class="index.checked == true ? 'cutListStyleT' : 'cutListStyleF'" @click="loginWayClick(index)">{{
                index.title }}</span>
          </div>
          <!-- 登录 -->
          <div class="loginFrom" v-if="cutTitle == 'dl'">
            <input placeholder="手机号" type="number" maxlength="11" v-model="dlUser" @keydown="onmousedown" />
            <input placeholder="密码" type="password" v-model="dlPass" @keydown="onmousedown" />
            <div class="forgetPass">
              <span @click="moduleIDClick(3)">忘记密码? </span>
            </div>
            <button class="loginButton" @click="userLoginClick">登录</button>
            <button @click="moduleIDClick(2)" class="accountWX">
              <img src="../../assets/login_wx.png" />
              微信扫码
            </button>
          </div>
          <!-- 注册 -->
          <div class="registerFrom" v-if="cutTitle == 'zc'">
            <input placeholder="手机号" type="number" maxlength="11" v-model="register_dlUser" />
            <div class="registerFrom_yzm">
              <input type="text" placeholder="验证码" v-model="register_yzm" autocomplete="off" />
              <span @click="yzmClick" :style="register_yzm_title == '发送验证码' ? '' : 'color:#999;'">{{ register_yzm_title
              }}</span>
            </div>
            <input placeholder="密码" type="password" v-model="register_dlPass" autocomplete="new-password" />
            <div class="forgetPass">
              <span @click="moduleIDClick(3)">忘记密码? </span>
            </div>
            <button class="loginButton" @click="userRegisterClick">注册</button>
            <div class="readAndAgree">
              <el-checkbox-group v-model="register_userRegister">
                <el-checkbox v-model="register_userRegister" label="" name="type" />
              </el-checkbox-group>
              已阅读并同意
              <!-- <span>已阅读并同意</span> -->
              <span @click="openagreement('fwxy')">《标识山西用户服务协议》</span>
              <span>、</span>
              <span @click="openagreement('yszc')">《隐私政策》</span>
            </div>
            <button @click="moduleIDClick(2)" class="accountWX">
              <img src="../../assets/login_wx.png" />
              微信扫码
            </button>
          </div>
        </div>

      </div>

      <div class="TgWXSM alterPassBody" v-if="moduleID == 3">
        <div class="alterPass">
          <span class="alterPassTitle">重置密码</span>
          <!-- <div class="TgWXSM_open" @click="tgLoginClick">
          <img src="../../assets/module_share_open.png" />
        </div> -->
          <!-- <span class="alterPassOpen" @click="tgLoginClick"></span> -->
        </div>
        <input placeholder="账号" type="number" maxlength="11" v-model="alterPass_user" />
        <div class="registerFrom_yzm">
          <input type="text" placeholder="验证码" v-model="alterPass_yzm" />
          <span @click="alter_yzmClick" :style="alter_yzm_title == '发送验证码' ? '' : 'color:#999;'">{{ alter_yzm_title
          }}</span>
        </div>
        <input placeholder="密码" type="password" v-model="alterPass_pass" />
        <div class="alterPassBack">
          <span class="BackLogin" @click="moduleIDClick(1)">←返回登录</span>
        </div>
        <button class="loginButton" @click="backPassClick">重置并登录</button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onUnmounted } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import vueQr from "vue-qr/src/packages/vue-qr.vue";
import axios from "axios";
import { Axios } from "../../assets/js/ls_com_axios.js";

import { serverUrls } from "../../assets/js/ls_tgServerUrls.js";
export default defineComponent({
  name: "TgIndex",
  components: {
    vueQr,
  },
  setup(props, context) {
    // const handkeyCode = (e) => {
    //   if (e.keyCode == 13) {
    //     userLoginClick();
    //   }
    // };
    // window.addEventListener("keydown", handkeyCode, true); //开启监听键盘按下事件

    const router = useRouter();
    const tgLoginClick = function () {
      context.emit("TgLoginType", false);
    };
    const moduleID = ref("1"); // 1 手机号登录/注册 , 2微信登录 . 3修改密码
    const ProtocolStatus = ref(""); //
    let userOpenId = ref(""); // 微信登录未绑定获取的OpenID
    const cutList = ref([
      {
        title: "登录",
        checked: true,
      },
      {
        title: "注册",
        checked: false,
      },
    ]);
    const cutTitle = ref("dl");
    const loginWayClick = function (index) {
      for (let i = 0; i < this.cutList.length; i++) {
        if (this.cutList[i].title == index.title) {
          this.cutList[i].checked = true;
        } else {
          this.cutList[i].checked = false;
        }
      }
      if (index.title == "登录") {
        this.cutTitle = "dl";
      }
      if (index.title == "注册") {
        this.cutTitle = "zc";
      }
    };
    const dlUser = ref("");
    const dlPass = ref("");
    let codeUrl = ref("");
    /* 获取微信二维码 */
    const getWXurl = function () {
      Axios("GET", "/auth/member/wx-qrcode", null, false, function (res) {
        codeUrl.value = res.data.data.qrCodeUrl;
        heckCode(res.data.data.scene);
      });
    };
    let setTimer = ref();

    /* 检测微信扫码状态 */
    const heckCode = function (id) {
      if (setTimer.value) {
        setTimer.value;
      }
      clearInterval(setTimer.value);
      let timer = 60;
      setTimer.value = setInterval(function () {
        if (timer != 0) {
          timer -= 1;
          Axios(
            "GET",
            "/auth/member/wx-login/" + id,
            null,
            false,
            function (res) {
              // codeUrl.value = res.data.data.qrCodeUrl
              console.log(res.data.data);
              if (res.data.data.isScan) {
                // 未绑定手机号
                //
                userOpenId.value = res.data.data.openId;
                console.log(res.data.data);
                clearInterval(setTimer.value);
                ElMessage({
                  showClose: true,
                  message: "当前微信暂未绑定手机号,请前往注册或登录后进行绑定",
                  type: "warning",
                });
                moduleID.value = 1;
                cutList.value[1].checked = true;
                cutList.value[0].checked = false;
                cutTitle.value = "zc";
              } else {
                // 已绑定手机号
                console.log(res.data.data.access_token);
                if (res.data.data.access_token) {
                  ElMessage({
                    showClose: true,
                    message: "登录成功",
                    type: "success",
                  });
                  localStorage.setItem("token", res.data.data.access_token);
                  context.emit("TgLoginType", false);
                  context.emit("setloginType", true); // 登录状态
                  clearInterval(setTimer.value);

                  router.push("/");
                  // context.emit('loginType', true);
                  getuserList();
                }
              }
            }
          );
        } else {
          clearInterval(setTimer.value);
        }
      }, 1000);
    };
    /* 账号登录 */
    const userLoginClick = function () {
      if (dlUser.value == "") {
        ElMessage({
          showClose: true,
          message: "账号不可为空",
          type: "warning",
        });
        return;
      }
      const regMobile =
        /^(0|86|17951)?(16[0-9]|13[0-9]|15[012356789]|17[0-9]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/;
      if (!regMobile.test(dlUser.value)) {
        ElMessage({
          showClose: true,
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }
      if (dlPass.value == "") {
        ElMessage({
          showClose: true,
          message: "密码不可为空",
          type: "warning",
        });
        return;
      }
      /* 调接口吗，模拟成功 */

      let params = {
        username: dlUser.value,
        password: dlPass.value,
        smsCode: "string",
        userOpenId: "string",
      };
      Axios("post", "/auth/member/login", params, false, function (res) {
        if (res.data.code == 200) {
          localStorage.setItem("token", res.data.data.access_token);
          ElMessage({
            showClose: true,
            message: "登录成功",
            type: "success",
          });
          // router.go(-1);
          router.push("/");

          // context.emit("setloginType", true); // 登录状态
          // context.emit("TgLoginType", false);
          // sessionStorage.setItem('userpicture',)  //头像
          // router.go(0)
          // context.emit('loginType', true);
          getuserList();
        } else {
          ElMessage({
            showClose: true,
            message: "登录失败",
            type: "error",
          });
        }
      });
    };

    const onmousedown = () => {
      if (event.keyCode == 13) {
        userLoginClick();
      }
    };
    const getuserList = function () {
      Axios("get", "/member/login-info", null, false, function (res) {
        if (res.data.code == 200) {
          localStorage.setItem("userList", JSON.stringify(res.data.data));
        }
      });
    };
    /* 组件状态切换 微信登录 , 手机号登录和修改密码组件的切换 */
    const moduleIDClick = function (index) {
      if (index == 2) {
        getWXurl();
      }
      this.moduleID = index;
    };
    /* 注册的变量 */
    const register_dlUser = ref("");
    const register_dlPass = ref("");
    const register_yzm = ref("");
    const register_userRegister = ref(false);
    /* 验证码 */
    const register_yzm_title = ref("发送验证码");
    const yzm_time = ref(60);
    const yzm_setInterval = ref("");
    clearInterval(yzm_setInterval.value);
    const yzmClick = function (User) {
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[012356789]|16[0-9]|17[0-9]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/;
      if (!regMobile.test(register_dlUser.value)) {
        ElMessage({
          showClose: true,
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }

      let params = {
        mobile: register_dlUser.value,
        param: {},
        smsEvent: "MOBILE_REGISTER",
      };

      Axios("post", "/system/sms/sendSms", params, false, function (res) {
        if (register_yzm_title.value == "发送验证码") {
          yzm_setInterval.value = setInterval(() => {
            if (yzm_time.value != 0) {
              yzm_time.value = yzm_time.value - 1;
              register_yzm_title.value = yzm_time.value + " 秒";
            } else {
              register_yzm_title.value = "发送验证码";
              yzm_time.value = 60;
              clearInterval(yzm_setInterval.value);
            }
          }, 1000);
        }
      });
    };
    let loadingRegister = ref(false);
    /* 注册 */
    const userRegisterClick = function () {
      if (this.register_dlUser == "") {
        ElMessage({
          showClose: true,
          message: "账号不可为空",
          type: "warning",
        });
        return;
      }
      const regMobile =
        /^(0|86|17951)?(16[0-9]|13[0-9]|15[012356789]|17[0-9]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/;
      if (!regMobile.test(this.register_dlUser)) {
        ElMessage({
          showClose: true,
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }
      if (this.register_dlPass == "") {
        ElMessage({
          showClose: true,
          message: "密码不可为空",
          type: "warning",
        });
        return;
      }
      if (!register_userRegister.value) {
        ElMessage({
          showClose: true,
          message: "请先阅读并同意用户协议和隐私协议",
          type: "warning",
        });
        return;
      }
      /* 调接口吗，模拟成功 */
      let params = {
        username: register_dlUser.value,
        password: register_dlPass.value,
        smsCode: register_yzm.value,
        userOpenId: null,
      };
      if (userOpenId.value != "") {
        params.userOpenId = userOpenId.value;
      }
      loadingRegister.value = true;
      Axios("post", "/auth/member/register", params, false, function (res) {
        console.log(res);
        console.log(res.code);
        if (res.data.code == 200) {
          let params2 = {
            username: register_dlUser.value,
            password: register_dlPass.value,
            smsCode: "string",
            userOpenId: "string",
          };
          Axios("post", "/auth/member/login", params2, false, function (res) {
            if (res.data.code == 200) {
              localStorage.setItem("token", res.data.data.access_token);
              ElMessage({
                showClose: true,
                message: "注册成功,已完成登录",
                type: "success",
              });
              router.go(-1);
              userOpenId.value = null; // 清空微信扫码登录缓存的openID
              // context.emit("setloginType", true); // 登录状态
              // context.emit("TgLoginType", false);
              // context.emit('loginType', true);
              getuserList();
              /* 销毁定时器 */
              // this.register_yzm_title = "发送验证码";
              clearInterval(alter_yzm_setInterval.value);
            }
          }, function (res) {
            loadingRegister.value = false;
            ElMessage({
              showClose: true,
              message: res.data.msg,
              type: "warning",
            });
          });
        }
      }, function (res) {
        loadingRegister.value = false;
        ElMessage({
          showClose: true,
          message: res.data.msg,
          type: "warning",
        });
      });
    };
    const alterPass_user = ref("");
    const alterPass_pass = ref("");
    const alterPass_yzm = ref("");
    const alter_yzm_title = ref("发送验证码");
    const alter_yzm_time = ref(60);
    const alter_yzm_setInterval = ref("");
    const alter_yzmClick = function () {
      const regMobile =
        /^(0|86|17951)?(16[0-9]|13[0-9]|15[012356789]|17[0-9]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/;
      if (!regMobile.test(alterPass_user.value)) {
        ElMessage({
          showClose: true,
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }

      let params = {
        mobile: alterPass_user.value,
        // param: {},
        smsEvent: "RESET_PASSWORD",
      };
      console.log(params);
      Axios("post", "/system/sms/sendSms", params, false, function (res) {
        if (alter_yzm_title.value == "发送验证码") {
          alter_yzm_setInterval.value = setInterval(() => {
            if (alter_yzm_time.value != 0) {
              alter_yzm_time.value = alter_yzm_time.value - 1;
              alter_yzm_title.value = alter_yzm_time.value + " 秒";
            } else {
              alter_yzm_title.value = "发送验证码";
              alter_yzm_time.value = 60;
              clearInterval(alter_yzm_setInterval.value);
            }
          }, 1000);
        }
      });
    };

    /* 重置密码 - 登录 */
    const backPassClick = function () {
      if (alterPass_user.value == "") {
        ElMessage({
          showClose: true,
          message: "账号不可为空",
          type: "warning",
        });
        return;
      }
      const regMobile =
        /^(0|86|17951)?(16[0-9]|13[0-9]|15[012356789]|17[0-9]|18[0-9]|14[57]|19[0-9])[0-9]{8}$/;
      if (!regMobile.test(alterPass_user.value)) {
        ElMessage({
          showClose: true,
          message: "请输入正确的手机号",
          type: "warning",
        });
        return;
      }
      if (alterPass_yzm.value == "") {
        ElMessage({
          showClose: true,
          message: "验证码不可为空",
          type: "warning",
        });
        return;
      }
      if (alterPass_pass.value == "") {
        ElMessage({
          showClose: true,
          message: "密码不可为空",
          type: "warning",
        });
        return;
      }
      let params = {
        password: alterPass_pass.value,
        smsCode: alterPass_yzm.value,
        phonenumber: alterPass_user.value,
      };
      Axios("POST", `/member/update-new-pwd`, params, false, function (res) {
        /* 调接口吗，模拟成功 */
        ElMessage({
          showClose: true,
          message: "修改密码成功",
          type: "success",
        });
        // Axios('')
        /* 销毁定时器 */
        alterPass_yzm.value = "发送验证码";
        clearInterval(alter_yzm_setInterval.value);
        tgLoginClick();
      });
    };
    const openagreement = (val) => {
      window.open(`/agreement?name=${val}`, "_bank");
    };
    return {
      tgLoginClick,
      moduleID,
      ProtocolStatus,
      // logoSrc: require('../assets/img/qrlogo.png'),
      // bgSrc: require('../assets/img/bgSrc.png'),
      codeUrl,
      cutList,
      loginWayClick,
      onmousedown,
      cutTitle,
      dlUser,
      dlPass,
      userLoginClick,
      moduleIDClick,
      register_yzm,
      register_dlPass,
      register_dlUser,
      userRegisterClick,
      register_yzm_title,
      yzmClick,
      yzm_setInterval,
      yzm_time,
      alterPass_user,
      alterPass_pass,
      alterPass_yzm,
      alter_yzm_title,
      alter_yzm_time,
      alter_yzm_setInterval,
      alter_yzmClick,
      backPassClick,
      register_userRegister,
      router,
      userOpenId,
      openagreement,
      loadingRegister
    };
  },
});
</script>
<style scoped>
input {
  font-size: 0.14rem;
}

.TgLogin {
  position: relative;
  width: 100%;
  height: 100%;
}

.TgWXSM {
  padding-top: 0.6rem;
  padding-bottom: 0.12rem;
  margin: 0 auto;
  width: 4.8rem;
  background: #fff !important;
  border-radius: 0.12rem;
  top: 50%;
}

.TgWXSM_open {
  width: 0.2rem;
  height: 0.2rem;
  background: #f2f3f5;
  border-radius: 50%;
  position: absolute;
  top: 0.18rem;
  right: 0.26rem;
}

.TgWXSM_open>img {
  width: 100%;
}

.TgWXSM_title1 {
  text-align: center;
  color: #333;
  font-size: 0.16rem;
}

.TgWXSM_title2 {
  margin-top: 0.08rem;
  text-align: center;
  font-size: 0.12rem;
  color: #86909c;
}

.accountButton,
.accountWX {
  border: 0.01rem solid #e5e6eb;
  border-radius: 0.12rem;
  padding: 0.12rem 0.24rem;
  color: #333;
  font-size: 0.14rem;
  text-align: center;
  margin: 0.2rem auto;
  display: block;
  background: #fff;
}

.accountButton>img,
.accountWX>img {
  width: 0.16rem;
  margin-right: 0.04rem;
}

.cutListStyleT {
  font-size: 0.24rem;
  color: #C01A26;
  margin: 0 auto;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  border-bottom: 0.02rem solid #C01A26;
  padding-bottom: 0.05rem;
}

.cutListStyleF {
  font-size: 0.24rem;
  color: #333;
  margin: 0 auto;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.loginFrom,
.registerFrom {
  margin-top: 0.36rem;
}

.loginFrom>input,
.registerFrom>input,
.alterPassBody>input,
.registerFrom>.registerFrom_yzm,
.alterPassBody>.registerFrom_yzm {
  width: 4rem;
  display: block;
  margin: 0 auto;
  border: 0.01rem solid #e5e6eb;
  border-radius: 0.12rem;
  padding: 0.12rem;
  margin-bottom: 0.12rem;
}

.forgetPass {
  width: 4rem;
  margin: 0 auto;
  margin-bottom: 0.2rem;
  overflow: hidden;
}

.forgetPass>span {
  color: #333;
  font-size: 0.12rem;
  float: right;
}

.loginButton {
  display: block;
  width: 4rem;
  margin: 0.2rem auto;
  text-align: center;
  padding: 0.16rem 0;
  background: #C01A26;
  border-radius: 0.12rem;
  font-size: 0.14rem;
  color: #fff;
  border: 0rem solid #C01A26;
}

.registerFrom_yzm {
  overflow: hidden;
}

.registerFrom_yzm>input {
  border: 0px solid #fff;
  width: 30%;
  float: left;
  font-size: 0.14rem;
}

.registerFrom_yzm>span {
  color: #C01A26;
  font-size: 0.14rem;
  float: right;
}

.alterPass,
.alterPassBack {
  width: 4rem;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 0.16rem;
  margin-bottom: 0.2rem;
}

.alterPass>.alterPassTitle {
  font-size: 0.16rem;
  color: #333;
  float: left;
}

.alterPass>.alterPassOpen {
  width: 0.2rem;
  height: 0.2rem;
  background: #f2f3f5;
  border-radius: 50%;
  float: right;
}

.BackLogin {
  font-size: 0.12rem;
  color: #333;
}

.readAndAgree {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
}

.readAndAgree>span {
  color: #C01A26;
}

.rwm_body {
  width: 1.8rem;
  height: 1.8rem;
  margin: 0 auto;
  display: block;
  margin-top: 0.2rem;
  border-radius: 0.12rem;
  border: 0.01rem solid #e5e6eb;
  padding: 0.1rem;
  position: relative;
}

.rwm_body>div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.rwm_body>div>img {
  width: 0.3rem;
  animation: rotate 2s linear infinite;
  -webkit-animation: rotate 2s linear infinite;
}

.rwm_body>div>span {
  color: #fff;
  margin-top: 0.2rem;
  font-size: 0.16rem;
  font-weight: 500;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Logo图片 */
.img_logo {
  width: 7%;
  display: block;
  margin: 0 auto;
  margin-top: 1.08rem;
  z-index: 1;
}

/* Logo下字体 */
.img_logosize {
  font-size: 0.2rem;
  text-align: center;
  margin-bottom: 0.4rem;
  z-index: 1;
  color: #fff;
}

/* .logi_bor {
  position: relative
} */


@media screen and (max-width: 900px) {
  .img_logo {
    width: 600px;
  }
}

@media screen and (max-width: 650px) {
  .img_logo {
    width: 40%;
    display: block;
    margin: 0 auto;
    margin-top: 0.5rem;
    z-index: 1;
  }

  .img_logosize {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0.3rem;
    z-index: 1;
    color: #fff;
  }

  .TgWXSM {
    padding-top: 0.2rem;
    padding-bottom: 0.12rem;
    margin: 0 auto;
    width: 90%;
    background: #fff !important;
    border-radius: 0.12rem;
    top: 50%;
  }

  .loginFrom,
  .registerFrom {
    margin-top: 0.36rem;
  }

  .loginFrom>input,
  .registerFrom>input,
  .alterPassBody>input,
  .registerFrom>.registerFrom_yzm,
  .alterPassBody>.registerFrom_yzm {
    width: 90%;
    display: block;
    margin: 0 auto;
    border: 0.01rem solid #e5e6eb;
    border-radius: 0.12rem;
    padding: 0.12rem;
    margin-bottom: 0.12rem;
  }

  .forgetPass {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 0.2rem;
    overflow: hidden;
  }

  .forgetPass>span {
    color: #333;
    font-size: 0.12rem;
    float: right;
  }

  .loginButton {
    display: block;
    width: 90%;
    margin: 0.2rem auto;
    text-align: center;
    padding: 0.16rem 0;
    background: #C01A26;
    border-radius: 0.12rem;
    font-size: 0.14rem;
    color: #fff;
    border: 0rem solid #C01A26;
  }

  .alterPass,
  .alterPassBack {
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 0.16rem;
    margin-bottom: 0.2rem;
  }

  .alterPass>.alterPassTitle {
    font-size: 0.16rem;
    color: #333;
    float: left;
  }

  .alterPass>.alterPassOpen {
    width: 0.2rem;
    height: 0.2rem;
    background: #f2f3f5;
    border-radius: 50%;
    float: right;
  }
}
</style>
