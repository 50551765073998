<template>
  <view style="width: 100%; height: 100%">
    <view class="body">
      <view style="margin-bottom: 0.2rem">
        <view class="bodyUser">
          <img class="bodyUserImg" :src="item.avatar ? item.avatar : require('@/assets/logo.png')" />
          <!-- <img class="SL_CB_list_user_img" :src="item.avatar" /> -->
          <view class="bodyUsertitle">
            <view>
              <span class="BUT_name">{{ item.nickName }}</span>
              <!-- <img class="BUT_yh1" src="../assets/logo.png" />
							<img class="BUT_yh2" src="../assets/logo.png" /> -->
            </view>
            <view class="BUT_sf" v-for="(job, index) in jobs" :key="index">{{
              job
            }}</view>
          </view>
        </view>
        <view v-if="item.createUserId != memberId">
          <!-- <view class="BU_lx" @click="goPage">联系</view> -->

          <view v-if="ids.includes(Number(item.createUserId))" class="BU_gz" style="
              color: #fff;
              background: #86909c;
              border: 0.01rem solid #86909c;
            " @click="handleDelete">已关注</view>
          <view class="BU_gz" @click="handleAdd" v-else>关注</view>
        </view>
      </view>
      <!-- 视频插件 -->
      <view style="margin-bottom: 0.2rem">
        <video-player class="custom-video-player" ref="videoPlayerRef" :options="playerOptions"></video-player>
      </view>
      <!-- 内容 -->
      <view class="body_video">
        <view class="body_video_left">
          <p class="body_VL_name">{{ item.title }}</p>
          <p class="body_VL_xx">
            <span>{{ item.category1 }}-{{ item.category2 }}</span> |
            <!-- <span>{{ item.createTime }}</span>  -->
            <span>{{
              com_date.com_date.timestamp_string(
                item.createTime,
                true,
                "-",
                ":"
              )
            }}</span>

            |
            <span v-if="item.publishAddress">{{ item.publishAddress }}</span>
            <span v-else>未知</span>
            <span style="margin-left: 5px" v-if="item.authorizationMode != 1">
              <div v-if="AuthorizationModeList[item.authorizationMode]">
                <img v-for="(img, imgIndex) in AuthorizationModeList[
                  item.authorizationMode
                ].img" :key="imgIndex" :src="require(`@/assets/${img}`)" style="
                    width: 20px;
                    height: 20px;
                    border: 1px solid #fff;
                    border-radius: 50%;
                  " />
              </div>
            </span>
            <span style="margin-left: 5px" v-else class="circle-span"></span>
          </p>
          <view class="body_VL_message">
            <view class="body_VL_messageList">
              <view>
                <img src="@/assets/moduleSIWP_bf.png" />
                {{ item.plays }}
              </view>
              <view @click="handleLike">
                <img :src="handleLikeType
                  ? require('@/assets/moduleSIWP_dz.png')
                  : require('@/assets/moduleSIWP_dzLanSe.png')
                  " />

                {{ item.likes }}
              </view>
              <view @click="handleCollect">
                <img :src="handleCollectType
                  ? require('@/assets/moduleSIWP_sc.png')
                  : require('@/assets/moduleSIWP_scLanSe.png')
                  " />
                {{ item.favorites }}
              </view>
              <!-- <view @click="ParticularsOver">
                <img src="../assets/musicLJ.png" />
                复制链接
              </view> -->
            </view>
            <view @click="module_informOver" v-if="Object.keys(item).length && memberId != (item as any).createUserId"
              class="body_VL_message_jb">
              <img src="@/assets/moduleSIWP_jb.png" />
              举报
            </view>
          </view>
          <!-- 视频简介 -->
          <view class="body_VL_introduction">
            <p class="body_VL_introduction_title">视频简介</p>
            <p class="body_VL_introduction_nr">
              {{ item.introduction }}
            </p>
            <view class="body_VL_introduction_label">
              <view :key="index" v-for="(tag, index) in tags"> {{ tag }}</view>
            </view>
          </view>
        </view>

        <view class="body_video_right">
          <view class="body_video_right_t">
            <span class="body_video_right_t_title"> 创作人其他作品 </span>
            <view class="body_video_right_t_nav" @click="goPage">
              查看全部 >
            </view>
          </view>
          <template v-if="list.length">
            <view class="body_video_list" @click="showInfo(o)" v-for="(o, index) in list" :key="index">
              <view class="body_video_list_left">
                <img class="body_VLL_img" :src="o.coverSheet" style="width: 158px; height: 93px" />
                <span class="body_VLL_time">{{ o.videoTime }}</span>
              </view>
              <view class="body_VLL_title">
                <p class="body_VLL_t_title">{{ o.title }}</p>
                <p class="body_VLL_t_gg">{{ o.category1 }}-{{ o.category2 }}</p>
              </view>
            </view>
          </template>
          <template v-else>
            <view class="body_video_list">
              <img class="no_data" src="@/assets/zt_404.png" />
            </view>
          </template>
          <!-- 暂时隐藏 -->
          <view class="body_video_right_t" v-if="false">
            <span class="body_video_right_t_title"> 相关推荐 </span>
            <!-- <view class="body_video_right_t_nav">
							查看全部 >
						</view> -->
          </view>
          <!-- <template  v-if="false">
            <view
              class="body_video_list"
              @click="showInfo(o)"
              v-for="(o, index) in otherlist"
              :key="index"
            >
              <view class="body_video_list_left">
                <img
                  class="body_VLL_img"
                  :src="o.coverSheet"
                  style="width: 158px; height: 93px"
                />
                <span class="body_VLL_time">{{ o.videoTime }}</span>
              </view>
              <view class="body_VLL_title">
                <p class="body_VLL_t_title">{{ o.title }}</p>
                <p class="body_VLL_t_gg">{{ o.category1 }}-{{ o.category2 }}</p>
              </view>
            </view>
          </template>
          <template v-else>
            <view class="body_video_list">
              <img class="no_data" src="../assets/zt_404.png" />
            </view>
          </template> -->
        </view>
      </view>
    </view>
    <!-- absolute -->

    <view class="open_list">
      <img @click="openClick" class="open" src="@/assets/publick_open.png" />
      <view class="open_list_img">
        <img class="bodyUserImg" :src="item.avatar ? item.avatar : require('@/assets/logo.png')" />

        <button class="open_list_img_spanN" v-if="ids.includes(Number(item.createUserId)) &&
          item.createUserId != memberId
          " style="color: #fff; background: #86909c" @click="handleDelete">
          已关注
        </button>
        <button class="open_list_img_spanN" @click="handleAdd" v-else-if="item.createUserId != memberId">
          关注
        </button>
      </view>
      <view class="open_list_xx">
        <view @click="handleLike">
          <img :src="handleLikeType
            ? require('@/assets/moduleSIWP_dz.png')
            : require('@/assets/moduleSIWP_dzLanSe.png')
            " />
          {{ item.likes }}
        </view>
        <view @click="handleCollect">
          <img :src="handleCollectType
            ? require('@/assets/moduleSIWP_sc.png')
            : require('@/assets/moduleSIWP_scLanSe.png')
            " />

          {{ item.favorites }}
        </view>
        <!-- <view v-if="item.commentPermissions == 1">
          <img src="../../src/assets/moduleSIWP_xx.png" />
          {{ item.commentNum }}
        </view>
       
      -->
      </view>
    </view>
    <!-- 6.8W -->
    <!-- <view @click="ParticularsOver">
          <img style="margin-top: 0.08rem" src="../../src/assets/musicLJ.png" />
        </view> -->
    <!-- 高层级组件 - 分享 -->
    <view v-if="ParticularsChecked" class="module_share">
      <moduleShare @ParticularsOverNo="ParticularsOverNo" :rwm="rwm" :showImage="item.coverSheet"></moduleShare>
    </view>
    <!-- 高层级组件 - 举报 -->
    <view v-if="module_informChecked" class="module_inform">
      <view class="module_inform_body">
        <view class="module_share_body_top">
          <view>选择举报理由</view>
          <img @click="module_informOverNo" src="@/assets/module_share_open.png" />
        </view>
        <view>
          <view>
            <el-radio-group v-model="type">
              <el-row :gutter="20">
                <el-col :span="8"><el-radio label="1">违法违禁</el-radio></el-col>
                <el-col :span="8"><el-radio label="2">政治敏感</el-radio></el-col>
                <el-col :span="8"><el-radio label="3">色情低俗</el-radio></el-col>
                <el-col :span="8"><el-radio label="4">人身攻击</el-radio></el-col>
                <el-col :span="8"><el-radio label="5">垃圾广告</el-radio></el-col>
                <el-col :span="8"><el-radio label="6">刷屏</el-radio></el-col>
                <el-col :span="8"><el-radio label="7">其他问题</el-radio></el-col>
              </el-row>
            </el-radio-group>
          </view>
          <textarea placeholder="" maxLength="100" v-model="reason" class="module_share_textarea"></textarea>
          <view class="module_share_button">
            <button class="module_share_button_Y" @click="handleReport">
              确定
            </button>
            <button class="module_share_button_N" @click="module_informOverNo">
              取消
            </button>
          </view>
        </view>
      </view>
    </view>
  </view>
</template>

<script lang="ts" setup>
import com_date from "@/assets/js/common.js";
import { useRouter } from "vue-router";
import moduleShare from "../../../components/moduleShare.vue";
import { ref } from "vue";
import { Axios } from "@/assets/js/ls_com_axios.js";
import { ElMessage } from "element-plus";
const emit = defineEmits([
  "openClickA",
  "openClickOverClose",
  "openClickOverOpen",
  "openShowInfo",
]);
const router = useRouter();
const type = ref('');
// const bodyStyle = document.body.style;
const props = defineProps({
  workId: String,
});
const jobs = ref([]);
const tags = ref([]);
const item = ref({});
const reason = ref("");
const list = ref([]);
const otherlist = ref([]);
const ids = ref([]);
let id = ref("");
const memberId = ref(localStorage.getItem("userList") ? JSON.parse(localStorage.getItem("userList")).memberId : "");
const AuthorizationModeList = ref({
  1: {},
  2: {
    img: [
      "AuthorizationMode3.png",
      "AuthorizationMode4.png",
      "AuthorizationMode1.png",
    ],
  },
  3: {
    img: [
      "AuthorizationMode3.png",
      "AuthorizationMode4.png",
      "AuthorizationMode2.png",
    ],
  },
  4: {
    img: ["AuthorizationMode3.png", "AuthorizationMode4.png"],
  },
  5: {
    img: ["AuthorizationMode3.png", "AuthorizationMode1.png"],
  },
  6: {
    img: ["AuthorizationMode3.png", "AuthorizationMode2.png"],
  },
  7: {
    img: ["AuthorizationMode3.png"],
  },
});
const getInfo = () => {
  Axios(
    "GET",
    "/material/material/work/" + id.value,
    null,
    false,
    function (res) {
      item.value = res.data.data;
      console.log(res.data.data);
      console.log(res.data.data);

      playerOptions.value.sources[0].src = item.value.video;
      if (item.value.tags) {
        tags.value = item.value.tags.split(",");
      }
      if (item.value.jobs) {
        jobs.value = item.value.jobs.split(",");
      }
      handleCollectType.value = item.value.isFavorite == 1 ? true : false;
      handleLikeType.value = item.value.isLike == 1 ? true : false;
      getList();
      getOtherList();
      getuserMassage();
      Axios(
        "POST",
        "/material/material/work/play?id=" + id.value,
        null,
        false,
        function (res) {
          item.value.plays++;
        }
      );
    }
  );
};
const idsfollow = () => {
  // member / member - follow / follow - ids
  Axios("GET", "/member/member-follow/follow-ids", null, false, function (res) {
    console.log("res", res);
    ids.value = res.data.data;
  });
};

const getInfo1 = () => {
  Axios(
    "GET",
    "/material/material/work/" + id.value,
    null,
    false,
    function (res) {
      item.value = res.data.data;
      handleCollectType.value = item.value.isFavorite == 1 ? true : false;
      handleLikeType.value = item.value.isLike == 1 ? true : false;
    }
  );
};
const rwm = ref("");
const share = () => {
  Axios(
    "POST",
    "/material/material/work/share?id=" + id.value,
    null,
    false,
    function (res) {
      console.info(res);
      rwm.value = res.data.data.qrcodeUrl;
    }
  );
};
const isFollow = ref(true);
let getuserMassage = function () {
  Axios(
    "GET",
    "/member/info/" + item.value.createUserId,
    false,
    false,
    function (res) {
      console.log(res);
      isFollow.value == res.data.data.isFollow;
    }
  );
};
const videoPlayerRef = ref();
const openClick = function () {
  videoPlayerRef.value.player.pause(); // 关闭时暂停视频
  emit("openClickA"); // bodyStyle.overflowY = "auto";
};
const ParticularsChecked = ref(false);
const ParticularsOver = function () {
  var cInput = document.createElement("input");
  cInput.value = window.location.href;
  document.body.appendChild(cInput);
  cInput.select(); // 选取文本框内容

  // 执行浏览器复制命令
  // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
  // Input要在正常的编辑状态下原生复制方法才会生效

  document.execCommand("copy");
  // ParticularsChecked.value = true;
  // emit("openClickOverClose");
};
const ParticularsOverNo = function () {
  ParticularsChecked.value = false;
  emit("openClickOverOpen");
};
const module_informChecked = ref(false);
const module_informOver = function () {
  module_informChecked.value = true;
  emit("openClickOverClose");
};
const module_informOverNo = function () {
  module_informChecked.value = false;
  emit("openClickOverOpen");
};
const handleReport = function () {
  if (type.value == "") {
    // 添加提示必填项
    ElMessage.error("请选择举报类型");
  } else {
    Axios(
      "POST",
      "/material/material/workReport/sou",
      { reason: reason.value, type: type.value, workId: id.value },
      null,
      false,
      function (res) {
        ElMessage.success("举报成功");
        module_informChecked.value = false;
        emit("openClickOverOpen");
      }
    );
  }
};
const getList = function () {
  Axios(
    "POST",
    "/material/material/work/otherWorks",
    {
      condition: {
        publish_mode: 2,
        userId: item.value.createUserId,
        workId: id.value,
      },
      pageIndex: 1,
      pageSize: 3,
      sort: "likes",
    },
    null,
    false,
    function (res) {
      list.value = res.data.data.records;
    }
  );
};
const getOtherList = function () {
  Axios(
    "POST",
    "/material/material/work/list",
    {
      condition: {
        publish_mode: 2,
        category1_id: item.value.category1Id,
      },
      pageIndex: 1,
      pageSize: 4,
      sort: "likes",
    },
    null,
    false,
    function (res) {
      otherlist.value = res.data.data.records;
    }
  );
};
const playerOptions = ref({
  autoplay: false,
  controls: true,
  muted: false,
  controlBar: {
    durationDisplay: true,
    volumePanel: {
      inline: false,
    },
  },
  sources: [
    {
      src: "",
      type: "video/mp4",
    },
  ],
});
const goPage = function () {
  router.push({
    path: "ShadowComposerHomepage",
    query: { id: item.value.createUserId },
  });
  openClick();
};
const handleLikeType = ref(false); // 点赞状态

const handleLike = function () {
  Axios(
    "POST",
    "/material/material/work/like?id=" + id.value,
    null,
    false,
    function (res) {
      // if (handleLikeType.value == true) {
      //   item.value.likes = item.value.likes + 1;
      // } else {
      //   item.value.likes = item.value.likes - 1;
      // }
      // handleLikeType.value = !handleLikeType.value;
      getInfo1();
      // console.log(item.value);
    }
  );
};
const handleCollectType = ref(false); // 收藏状态
const handleCollect = function () {
  Axios(
    "POST",
    "/material/material/work/favorite?id=" + id.value,
    null,
    false,
    function (res) {
      // if (handleCollectType.value == true) {
      //   item.value.favorites = item.value.favorites + 1;
      // } else {
      //   item.value.favorites = item.value.favorites - 1;
      // }
      // handleCollectType.value = !handleCollectType.value;
      getInfo1();
    }
  );
};
const showInfo = function (o) {
  // module_informChecked.value = false;
  id.value = o.id;
  console.log("propspropspropspropspropspropsprops.workId ", id.value);

  item.value = {};
  init(1);
  // emit("openShowInfo", o.id);
};
const handleAdd = function () {
  Axios(
    "POST",
    "/member/member-follow",
    { memberId: item.value.createUserId },
    false,
    function (res) {
      ElMessage({
        showClose: true,
        message: "关注成功",
        type: "success",
      });
      // isFollow.value = true;
      getInfo1();
      idsfollow();
    }
  );
};
const handleDelete = function () {
  Axios(
    "DELETE",
    "/member/member-follow",
    { memberId: item.value.createUserId },
    false,
    function (res) {
      // console.log("ressssssssssssssssssssssssssssssss", res);
      // isFollow.value = false;
      ElMessage({
        showClose: true,
        message: "已取消关注",
        type: "success",
      });
      getInfo1();
      idsfollow();

      // getuserMassage();
    }
  );
};

const init = function async(type = 0) {
  console.log("typetypetypetypetypetypetypetypetypetypetypetypetype", type);

  if (props.workId) {
    type == 0 ? (id.value = props.workId) : "";
    if (id.value) {
      getInfo();
      idsfollow();
      if (localStorage.getItem("token") != null) {
        share();
      }
    }
  }
};

defineExpose({ init, openClick });
</script>

<style scoped>
/* html {
		overflow-y: clip;
	} */

.body {
  display: block;
  width: 12rem;
  background: #ffffff;
  border-radius: 0.12rem;
  padding: 0.2rem 0.4rem;
  margin: 0 auto;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.body>view,
.bodyUser,
.body_video_right_t,
.body_video_list,
.body_VL_message {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.body_video_right {
  margin-left: 0.4rem;
}

.body>view {
  align-items: flex-start;
}

.bodyUser {
  justify-content: flex-start;
}

.bodyUser>img {
  width: 0.4rem;
  height: 0.4rem;
  display: block;
  border-radius: 50%;
  /* padding-bottom: 0.24rem; */
  margin-right: 0.14rem;
}

.flex-start {
  font-size: 0.14rem;
  color: #000000;
}

.bodyUsertitle>view>img {
  width: 0.16rem;
  margin-right: 0.06rem;
}

.BUT_sf {
  display: block;
  color: #86909c;
  font-size: 0.12rem;
}

.BU_lx {
  border-radius: 0.04rem;
  border: 0.01rem solid #333333;
  font-size: 0.12rem;
  color: #333333;
  padding: 0.06rem 0.16rem;
  margin-right: 0.16rem;
}

.BU_gz {
  background: #c01a26;
  padding: 0.06rem 0.16rem;
  font-size: 0.12rem;
  color: #ffffff;
  border-radius: 0.04rem;
}

/* flex-grow: 1 */
.body_video {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: ; */

  justify-content: space-between;
}

.body_video_right {
  width: 3.6rem;
}

.body_video_left {
  width: 80%;
  /* flex-grow: 1; */
}

.body_video_right_t_title {
  font-size: 0.2rem;
  color: #333333;
}

.body_video_right_t_nav {
  color: #86909c;
  font-size: 0.14rem;
}

.body_video_list {
  margin-top: 0.2rem;
  display: flex;
  justify-content: flex-start;
}

.body_video_list_left {
  position: relative;
  margin-right: 0.12rem;
}

.body_VLL_img {
  width: 1.58rem;
  border-radius: 0.1rem;
}

.body_VLL_time {
  position: absolute;
  bottom: 0rem;
  right: 0rem;
  text-align: right;
  color: #ffffff;
  width: 100%;
  font-size: 0.12rem;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)); */
  padding-right: 0.16rem;
  height: 0.3rem;
  line-height: 0.3rem;
  /* border-radius: 0.5rem; */
}

.body_VL_message_jb {
  display: flex;
  align-items: center;
  font-size: 0.12rem;
  color: #86909c;
}

.body_VL_message_jb img {
  width: 0.16rem;
  margin-right: 0.04rem;
}

.body_VLL_t_title {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #333333;
  font-size: 0.14rem;
  line-height: 0.16rem;
  padding-bottom: 0.06rem;
}

.body_VLL_t_gg {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 0.12rem;
  color: #86909c;
  line-height: 0.14rem;
  /* -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 1; */
}

.body_VLL_user {
  margin-top: 0.08rem;
}

.body_VLL_user_img {
  width: 0.24rem;
  margin-right: 0.08rem;
}

.body_VLL_user_name {
  color: #000000;
  font-size: 0.12rem;
  margin-right: 0.08rem;
}

.body_VLL_user_hy1,
.body_VLL_user_hy2 {
  width: 0.16rem;
}

.body_VLL_user_hy1 {
  margin-right: 0.07rem;
}

.body_VL_name {
  font-size: 0.24rem;
  color: #333333;
}

.body_VL_xx {
  color: #86909c;
  font-size: 0.12rem;
  line-height: 0.14rem;
  margin-top: 0.12rem;
  margin-bottom: 0.2rem;
  display: flex;
  align-items: center;
}

.body_VL_message {
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-bottom: 0.01rem solid #e5e6eb;
}

.body_VL_messageList {
  font-size: 0.16rem;
  color: #333333;
  line-height: 0.19rem;
  display: flex;
  flex-grow: 1;
}

.body_VL_messageList>view {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* align-items: center;
		flex-direction:row;
		justify-content: space-between; */
  margin-right: 0.24rem;
}

.body_VL_messageList img {
  width: 0.24rem;
  margin-right: 0.04rem;
}

.body_VL_introduction {
  padding: 0.2rem 0;
  display: block;
}

.body_VL_introduction_title {
  font-size: 0.14rem;
  color: #000000;
  line-height: 0.16rem;
  padding-bottom: 0.08rem;
}

.body_VL_introduction_nr {
  font-size: 0.14rem;
  color: #000000;
  line-height: 0.18rem;
}

.body_VL_introduction_label {
  margin-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: block;
  border-bottom: 0.01rem solid #e5e6eb;
}

.body_VL_introduction_label>view {
  background: #f2f3f5;
  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
  font-size: 0.12rem;
  color: #86909c;
  padding: 0.04rem 0.12rem;
  margin-right: 0.12rem;
}

.open {
  width: 0.4rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.26rem;
}

.open:hover {
  cursor: pointer;
}

.open_list {
  position: fixed;
  top: 0.9rem;
  right: 0.45rem;
}

.open_list>view {
  display: block;
}

.open_list_img>img {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: block;
}

.open_list_img>button {
  position: relative;
  margin: 0 auto;
  display: block;
  border: 0rem solid #fff;
  top: -0.1rem;
}

.open_list_img_spanY {}

.open_list_img_spanN {
  font-size: 0.1rem;
  color: #ffffff;
  padding: 0.02rem 0.06rem;
  background: #c01a26;
  border-radius: 1rem 1rem 1rem 1rem;
}

.open_list_xx>view {
  display: block;
  background: #fff;
  padding: 0.07rem;
  border-radius: 50%;
  text-align: center;
  margin-top: 0.24rem;
  width: 0.5rem;
  height: 0.5rem;
  cursor: pointer;
}

.open_list_xx>view>img {
  width: 0.2rem;
  display: block;
  margin: 0 auto;
}

.module_share,
.module_inform {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: saturate(21%) blur(3px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.module_inform_b_top {}

.module_inform_body {
  width: 4.36rem;
}

.module_share_textarea {
  width: 100%;
  resize: none;
  padding: 0.12rem 0.16rem;
  color: #86909c;
  font-size: 0.14rem;
  height: 1.04rem;
  border-radius: 0.12rem;
  margin-top: 0.32rem;
  margin-bottom: 0.2rem;
}

.module_share_button {
  display: flex;
  justify-content: center;
}

.module_share_button>button {
  padding: 0.08rem 0.3rem;
  font-size: 0.14rem;

  border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
}

.module_share_button_Y {
  margin-right: 0.2rem;
  border: 0.01rem solid #333333;
  color: #333;
}

.module_share_button_N {
  color: #fff;
  background: #c01a26;
  border: 0rem solid #fff;
}

/deep/.custom-video-player,
/deep/.video-js {
  width: 100% !important;
  height: 6.3rem !important;
}

.module_share_body,
.module_inform_body {
  background: #ffffff;
  border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
  padding: 0.17rem 0.24rem;
}

.module_share_body_top,
.module_share_main {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.21rem;
}

.module_share_body_top>span {
  color: #333333;
  font-size: 0.16rem;
}

.module_share_body_top>img {
  width: 0.2rem;
}

.module_share_main {
  border-bottom: 0.01rem solid #e5e6eb;
  padding-bottom: 0.2rem;
}

.module_share_main>img {
  width: 3.8rem;
  margin-right: 0.4rem;
}

.module_share_main_title {
  text-align: center;

  font-size: 0.14rem;
  color: #333333;
  line-height: 0.16rem;
}

.module_share_main_list {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.16rem;
}

.module_share_main_list>img {
  width: 0.4rem;
}

.no_data {
  width: 100%;
  height: 160px;
}

.circle-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid #86909c;
  border-radius: 50%;
  line-height: 13px;
  /* 调整行高以适应文字 */
  vertical-align: middle;
  /* 垂直居中对齐 */
}
</style>
