/* eslint-disable no-mixed-spaces-and-tabs */
import request from "./request.js";
import qs from "qs";
 
const baseUrl = 'https://api.biaoshisx.com/'
// 发送验证码
export function tg_system_sms_sendSms(params) {
	params = qs.stringify(params)
	console.log(params)
	return request({
	  url: baseUrl + "system/sms/sendSms" + params ? '/' + params : '',
	  // eslint-disable-next-line no-mixed-spaces-and-tabs
	  method: "post",
	  data:params
	});
}
//注册
export function tg_login_register(params) {
  return request({
    url: baseUrl + "/member/register" + params ? '/' + qs.stringify(params) : '',
    method: "post",
  });
}
// //退出
// export function authLogout(params) {
//   return request({
//     url: baseUrl + "/logout",
//     method: "get",
//   });
// }
// //获取用户数据
// export function getUserInfo(params) {
//   return request({
//     url: baseUrl + "/getUserInfo",
//     method: "get",
//     params:qs.stringfy(params)
//   });
// }
 
//其实，也不一定就是params，也可以是 query 还有 data 的呀！
//params是添加到url的请求字符串中的，用于get请求。会将参数加到 url后面。所以，传递的都是字符串。无法传递参数中含有json格式的数据
//而data是添加到请求体（body）中的， 用于post请求。添加到请求体（body）中，json 格式也是可以的。