<template>
  <el-dialog v-model="dialogVisible" style="background: transparent !important;box-shadow: none !important;"
    :show-close="false" :close-on-click-modal="false" width="70%" align-center @keyup.esc="openClick">
    <ShadowIndexWParticulars :workId="workID" ref="ShadowIndexWParticularsRef" class="Particulars"
      @openClickA="handleClose" :style="'overflow-y: auto;'" />
  </el-dialog>
</template>

<script lang="ts" setup>
import { ElMessage } from "element-plus";
import { ref, onUnmounted, nextTick, defineProps } from "vue";
import { Axios } from "@/assets/js/ls_com_axios.js";
import ShadowIndexWParticulars from "./ShadowIndexWParticulars.vue";
const props = defineProps({
  CloseQrCode: Function,
});
const ShadowIndexWParticularsRef = ref<any>();
const dialogVisible = ref(false);
let workID = ref("");

const init = async (id) => {
  dialogVisible.value = true;
  workID.value = id;
  await nextTick();
  ShadowIndexWParticularsRef.value
    ? ShadowIndexWParticularsRef.value.init()
    : "";
};
const openClick = () => {
  ShadowIndexWParticularsRef.value.openClick();
};

const handleClose = () => {
  dialogVisible.value = false;
};

defineExpose({ init, handleClose });
</script>

<style lang="less" scoped>
.center {
  text-align: center;

  & img {
    width: 230px;
    height: 230px;
  }
}

.timer {
  font-size: 2em;
  margin-bottom: 1em;
}

.Particulars {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vh);
  height: calc(100vh);
  /* min-height: calc(100vh); */
  // background: rgba(0, 0, 0, 0.8);
}
</style>
