import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "center" }
const _hoisted_2 = { class: "timer" }
const _hoisted_3 = ["src"]

import { ElMessage } from "element-plus";
import { ref, onUnmounted } from "vue";
import { Axios } from "@/assets/js/ls_com_axios.js";

export default /*@__PURE__*/_defineComponent({
  __name: 'TwoDimensionalCode',
  props: {
  CloseQrCode: Function,
},
  setup(__props, { expose: __expose }) {

const props = __props;
let paymentCheckTimer;
let countdownTimer;
let sceneId = "";
let sceneIdd = "";

let timeoutDuration = ref(6000); // 设定超时时间，例如：5分钟
let timeLeft = ref(timeoutDuration); // 剩余时间

const dialogVisible = ref(false);
const paymentQrCode = ref(""); // 替换成实际的二维码链接或数据

const timeFormat = (time) => {
  const minutes = Math.floor(time / 1000 / 60);
  const seconds = Math.floor((time / 1000) % 60);
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

const init = (e, id, idd) => {
  console.log(e, id, idd);

  sceneId = id;
  sceneIdd = idd + "";
  dialogVisible.value = true;
  paymentQrCode.value = e;
  timeoutDuration.value = 60000;
  checkoutPaymentStatus();
  startCountdown();
};
const getPaymentStatus = async () => {
  return new Promise(async (resolve) => {
    await Axios("get", "/pay/pay-status/" + sceneId, false, false, (res) => {
      resolve(res.data.data ? true : false);
      console.log("res.data.data", res.data.data);
    });
  });
};
const checkoutPaymentStatus = () => {
  // 在这里调用API获取支付状态，这只是一个示例，实际使用中你需要替换成真正的API调用
  let randomNumber = null;
  paymentCheckTimer = setInterval(async () => {
    randomNumber = await getPaymentStatus();
    //await getPaymentStatus();
    // console.log("randomNumber", randomNumber);
    // 当 randomNumber 等于2时，模拟支付成功
    // randomNumber = true;
    let status = {
      isSuccess: randomNumber === true,
    };

    if (status.isSuccess) {
      // console.log("支付成功");
      ElMessage.success("支付成功");
      clearInterval(paymentCheckTimer);
      clearInterval(countdownTimer);
      dialogVisible.value = false;

      if (typeof props.CloseQrCode === "function") {
        props.CloseQrCode(sceneIdd);
      }
    }
  }, 1000);
};

const startCountdown = () => {
  countdownTimer = setInterval(() => {
    timeLeft.value -= 1000; // 每秒减去1000毫秒

    if (timeLeft.value <= 0) {
      ElMessage.warning("支付超时");
      clearInterval(countdownTimer);
      clearInterval(paymentCheckTimer);
      dialogVisible.value = false;
    }
  }, 1000);
};

const handleClose = () => {
  dialogVisible.value = false;
  clearInterval(countdownTimer);
  clearInterval(paymentCheckTimer);
};

onUnmounted(() => {
  clearInterval(countdownTimer);
  clearInterval(paymentCheckTimer);
});

__expose({ init, handleClose });

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: dialogVisible.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialogVisible).value = $event)),
    title: "扫码二维码",
    "before-close": handleClose,
    class: "he",
    width: "20%",
    "close-on-click-modal": false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(timeFormat(_unref(timeLeft))), 1),
        _createElementVNode("img", {
          src: paymentQrCode.value,
          alt: "Payment QR Code"
        }, null, 8, _hoisted_3)
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})