import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ElMessage } from "element-plus";
import { ref, onUnmounted, nextTick } from "vue";
import { Axios } from "@/assets/js/ls_com_axios.js";
import ShadowIndexWParticulars from "./ShadowIndexWParticulars.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsOfWorks',
  props: {
  CloseQrCode: Function,
},
  setup(__props, { expose: __expose }) {

const props = __props;
const ShadowIndexWParticularsRef = ref<any>();
const dialogVisible = ref(false);
let workID = ref("");

const init = async (id) => {
  dialogVisible.value = true;
  workID.value = id;
  await nextTick();
  ShadowIndexWParticularsRef.value
    ? ShadowIndexWParticularsRef.value.init()
    : "";
};
const openClick = () => {
  ShadowIndexWParticularsRef.value.openClick();
};

const handleClose = () => {
  dialogVisible.value = false;
};

__expose({ init, handleClose });

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: dialogVisible.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((dialogVisible).value = $event)),
    style: {"background":"transparent !important","box-shadow":"none !important"},
    "show-close": false,
    "close-on-click-modal": false,
    width: "70%",
    "align-center": "",
    onKeyup: _withKeys(openClick, ["esc"])
  }, {
    default: _withCtx(() => [
      _createVNode(ShadowIndexWParticulars, {
        workId: _unref(workID),
        ref_key: "ShadowIndexWParticularsRef",
        ref: ShadowIndexWParticularsRef,
        class: "Particulars",
        onOpenClickA: handleClose,
        style: 'overflow-y: auto;'
      }, null, 8, ["workId"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})