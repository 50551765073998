// version:20200828 。 url路径
// import tgAppConfig from './tgAppConfig.js';
// import tgUserStorage from './tgUserStorage.js';
// import request from "./request.js";
// const config = tgAppConfig.appConfig; //  配置
// const serverIp = tgUserStorage.getServerIp() ? tgUserStorage.getServerIp() + ':' : `${config.serverIp}`;
// const serverPortPath = tgUserStorage.getServerPort() ? tgUserStorage.getServerPort() + '/' : `:${config.serverPort}/`;
// const serverNamePath = config.serverName ? `${config.serverName}/` : '';
// const serverBaseUrl = `${config.serverProtocal}://` + serverIp + serverPortPath + serverNamePath; // 基本路径
// const serverBaseUrl = 'http://bs.vvqc.cn'
// export const serverUrls = {
// 	// 公共部分
// 	tg_login_sms_sendSms: serverBaseUrl + '/system/sms/sendSms', // 发生验证码
// 	tg_login_register: serverBaseUrl + '/auth/member/register', // 注册
// 	tg_login_login: serverBaseUrl + '/auth/member/login', // 登录
// 	// tg_login_logout : serverBaseUrl + '/member/logout' , // 退出登录
// 	// tg_refresh : serverBaseUrl + '/member/refresh' , // 刷新token
// 	// tg_login_register : serverBaseUrl + '/member/register' , // 注册
// 	upload: serverBaseUrl + '/upload' // 上传
// }
// export default {
// 	serverUrls,
// 	serverBaseUrl,
// 	// serverIp,
// 	// serverPortPath,
// 	// serverNamePath
// }

export const serverBaseUrl = "https://api.biaoshisx.com";
// export const serverBaseUrl = "http://api.biaoshisx.com";

// export const serverBaseUrl = "http://192.168.1.35:8080";

// export const serverBaseUrl = 'http://uzv5yd.natappfree.cc/';
