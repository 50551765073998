<template>
	<view>
		<view class="module_share_body">
			<view class="module_share_body_top">
				<span>
					分享给他人
				</span>
				<img @click="ParticularsOverNo" src="../../src/assets/module_share_open.png" />
			</view>
			<view class="module_share_main">
				<img :src="props.showImage" />
				<view>
					<view>
						<!-- <vue-qr
							style=""
							:correctLevel="3" :autoColor="false" colorDark="#000" :text="rwm || codeUrl" :size="95" :margin="0"
							:logoMargin="3"></vue-qr> -->
						<img style="width:1.8rem;height:1.8rem;margin:0
							auto;display:block;margin-bottom:0.2rem;border-radius:0.12rem; border:0.01rem solid
							#E5E6EB;padding:0.1rem;" :src="props.rwm" />
						<p class="module_share_main_title">微信扫一扫</p>
					</view>
				</view>
			</view>
			<view class="module_share_main_list">
				<img src="../assets/module_share_qq.png" />
				<img src="../assets/module_share_wb.png" />
				<img src="../assets/module_share_qqkj.png" />
				<img src="../assets/module_share_lj.png" />
			</view>
		</view>
	</view>
</template>

<script setup>
	import {
		ref,
		defineProps,
	} from 'vue';
	// import {
	// 	useRouter
	// } from "vue-router";
	import vueQr from 'vue-qr/src/packages/vue-qr.vue'
	import {
		defineEmits
	} from 'vue'
	const props = defineProps({
		rwm: {},
		showImage: String
	})
	console.log(props.rwm)
	console.log('props.rwm')
	const emit = defineEmits(['openClickA'])
	const codeUrl = 'https://github.com/lx719679895'
	const ParticularsOverNo = function() {
		emit("ParticularsOverNo")
	}
</script>


<style scoped>
	.module_share {
		background: rgba(0, 0, 0, 0.2);
		backdrop-filter: saturate(21%) blur(3px);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 3;
	}

	.module_share_body,
	.module_inform_body {

		background: #FFFFFF;
		border-radius: 0.12rem 0.12rem 0.12rem 0.12rem;
		padding: 0.17rem 0.24rem;
		display: block;
	}

	.module_share_body_top,
	.module_share_main {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0.21rem;
	}

	.module_share_body_top>span {

		color: #333333;
		font-size: 0.16rem;
	}

	.module_share_body_top>img {
		width: 0.2rem;

	}

	.module_share_main {
		border-bottom: 0.01rem solid #E5E6EB;
		padding-bottom: 0.2rem;
	}

	.module_share_main>img {
		width: 3.8rem;
		margin-right: 0.4rem;
	}

	.module_share_main_title {
		text-align: center;

		font-size: 0.14rem;
		color: #333333;
		line-height: 0.16rem;
	}

	.module_share_main_list {
		display: flex;
		justify-content: space-around;
		margin-bottom: 0.16rem;
	}

	.module_share_main_list>img {
		width: 0.4rem;
	}
</style>