import { createStore } from 'vuex'

export default createStore({
    state: {
        uploadList: <unknown[]>[],
        SystemMessages:[],
    },
    getters: {},
    mutations: {
        SetSystemMessages(state, messages) { 
            console.log("messages",messages);
            
            state.SystemMessages = messages
        },
        updateUploadList(state, list) {
            state.uploadList = list
        },
        removeUpload(state, index) {
            state.uploadList.splice(index, 1)
        },
        addUpload(state, fileData) {
            if (state.uploadList.length == 0) {
                state.uploadList.push(fileData)
            } else {
                state.uploadList.unshift(fileData)
            }
        },
        updateUpload(state, { index, item }) {
            state.uploadList[index] = item
        },
    },
    actions: {
        
    },
    modules: {},
    
})
