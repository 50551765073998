// version: 20200709 。 用户存储

// import tgCommonUniUtils from "@/common/tgCommonUniUtils.js";
/*** 设置ip */
function setServerIp(ip) {
  tgCommonUniUtils.setStorage("tgServerIp", ip);
}
/*** 获取ip */
function getServerIp() {
  return tgCommonUniUtils.getStorageSync("tgServerIp");
}
/*** 设置port */
function setServerPort(port) {
  tgCommonUniUtils.setStorage("tgServerPort", port);
}
/*** 获取port */
function getServerPort() {
  return tgCommonUniUtils.getStorageSync("tgServerPort");
}
/*** 设置token */
function setToken(token) {
  tgCommonUniUtils.setAccessToken(token);
}
/*** 获取token */
function getToken() {
  return tgCommonUniUtils.getAccessToken();
}
/*** 设置tokenTime */
function setTokenTime(tokenTime) {
  tgCommonUniUtils.setStorage("tgTokenTime", tokenTime);
}
/*** 获取tokenTime */
function getTokenTime() {
  return tgCommonUniUtils.getStorageSync("tgTokenTime");
}
/*** 设置openId */
function setOpenId(tokenTime) {
  tgCommonUniUtils.setStorage("tgOpenId", tokenTime);
}
/*** 获取openId */
function getOpenId() {
  return tgCommonUniUtils.getStorageSync("tgOpenId");
}
/*** 设置userId*/
function setUserId(userId) {
  tgCommonUniUtils.setStorage("tgCurrentUserId", userId);
}
/*** 获取userId */
function getUserId() {
  return tgCommonUniUtils.getStorageSync("tgCurrentUserId");
}
/*** 设置userCodeNum*/
function setUserCodeNum(userCodeNum) {
  tgCommonUniUtils.setStorage("tgCurrentUserCodeNum", userCodeNum);
}
/*** 获取userCodeNum */
function getUserCodeNum() {
  return tgCommonUniUtils.getStorageSync("tgCurrentUserCodeNum");
}
/*** 设置username*/
function setUsername(username) {
  tgCommonUniUtils.setStorage("tgCurrentUsername", username);
}
/*** 获取username */
function getUsername() {
  return tgCommonUniUtils.getStorageSync("tgCurrentUsername");
}
/*** 设置realname*/
function setUserRealname(realname) {
  tgCommonUniUtils.setStorage("tgCurrentUserRealname", realname);
}
/*** 获取realname */
function getUserRealname() {
  return tgCommonUniUtils.getStorageSync("tgCurrentUserRealname");
}
/*** 设置cellphoneNumber*/
function setUserCellphoneNumber(cellphoneNumber) {
  tgCommonUniUtils.setStorage("tgCurrentUserCellphone", cellphoneNumber);
}
/*** 获取cellphoneNumber */
function getUserCellphoneNumber() {
  return tgCommonUniUtils.getStorageSync("tgCurrentUserCellphone");
}
/*** 设置email*/
function setUserEmail(email) {
  tgCommonUniUtils.setStorage("tgCurrentUserEmail", email);
}
/*** 获取email */
function getUserEmail() {
  return tgCommonUniUtils.getStorageSync("tgCurrentUserEmail");
}
/*** 设置头像*/
function setUserAvatarUrl(avatarUrl) {
  tgCommonUniUtils.setStorage("tgCurrentUserAvatarUrl", avatarUrl);
}
/*** 获取头像 */
function getUserAvatarUrl() {
  return tgCommonUniUtils.getStorageSync("tgCurrentUserAvatarUrl");
}
/*** 设置用户登录状态*/
function setUserLoginStatus(loginStatus) {
  tgCommonUniUtils.setStorage("tgLoginStatus", loginStatus);
}
/*** 获取用户登录状态 */
function getUserLoginStatus() {
  return tgCommonUniUtils.getStorageSync("tgLoginStatus");
}
/*** 清空信息 */
function clear() {
  uni.clearStorage();
}
export default {
  setServerIp,
  getServerIp,
  setServerPort,
  getServerPort,
  setToken,
  getToken,
  setTokenTime,
  getTokenTime,
  setOpenId,
  getOpenId,
  setUserId,
  getUserId,
  setUserCodeNum,
  getUserCodeNum,
  setUsername,
  getUsername,
  setUserRealname,
  getUserRealname,
  setUserCellphoneNumber,
  getUserCellphoneNumber,
  setUserEmail,
  getUserEmail,
  setUserAvatarUrl,
  getUserAvatarUrl,
  setUserLoginStatus,
  getUserLoginStatus,
  clear,
};
