<template>
  <router-link to="/"></router-link>
  <router-view />
</template>
<script>
localStorage.setItem("navType", "1"); // 1顶部透明 , 2顶部不透明 默认顶部透明
function browserRedirect() {
  var sUserAgent = navigator.userAgent.toLowerCase();
  var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  var bIsAndroid = sUserAgent.match(/android/i) == "android";
  var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  if (
    bIsIpad ||
    bIsIphoneOs ||
    bIsMidp ||
    bIsUc7 ||
    bIsUc ||
    bIsAndroid ||
    bIsCE ||
    bIsWM
  ) {
    //跳转移动端页面
    // window.location.href="http://f.jcngame.com/fanfan20171208/mobile/index.html";
    // console.log('手机端')
    // document.documentElement.style.fontSize = document.documentElement.clientWidth / 7.5 + 'px'
    // 执行对应函数
  } else {
    //跳转pc端页面
    // window.location.href="http://f.jcngame.com/fanfan20171208//fanmai/index.html";
    // console.log('PC端')
  }
}

browserRedirect();
</script>
<style lang="less">
@import "vue-video-player/src/custom-theme.css";
@import "video.js/dist/video-js.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.cur:hover {
  cursor: pointer;
}
p {
  margin: 0;
  margin-bottom: 0rem !important;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul,
li {
  list-style: none;
}

a,
textarea,
input,
button {
  outline: none;
}

ins,
a {
  text-decoration: none;
}

img {
  border: none;
}

li,
input,
img,
textarea,
select {
  vertical-align: middle;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ".";
  clear: both;
  height: 0;
}

* html .clearfix {
  zoom: 1;
}

.clears {
  clear: both;
  line-height: 0px;
  overflow: hidden;
  font-size: 0px;
  height: 0px;
}

body {
  font-family: Verdana, Arial, "Microsoft Yahei", "Pingfang SC", SimSun;
  font-size: 12px;
}

/* google、safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

/* 火狐 */
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 3px;
}
.com_cursor {
  cursor: pointer;
}
.el-dialog.is-align-center {
  border-radius: 20px;
}
</style>
