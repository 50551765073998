<template>
  <el-dialog
    v-model="dialogVisible"
    title="扫码二维码"
    :before-close="handleClose"
    class="he"
    width="20%"
    :close-on-click-modal="false"
  >
    <div class="center">
      <div class="timer">{{ timeFormat(timeLeft) }}</div>
      <img :src="paymentQrCode" alt="Payment QR Code" />
    </div>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ElMessage } from "element-plus";
import { ref, onUnmounted, defineProps } from "vue";
import { Axios } from "@/assets/js/ls_com_axios.js";
const props = defineProps({
  CloseQrCode: Function,
});
let paymentCheckTimer;
let countdownTimer;
let sceneId = "";
let sceneIdd = "";

let timeoutDuration = ref(6000); // 设定超时时间，例如：5分钟
let timeLeft = ref(timeoutDuration); // 剩余时间

const dialogVisible = ref(false);
const paymentQrCode = ref(""); // 替换成实际的二维码链接或数据

const timeFormat = (time) => {
  const minutes = Math.floor(time / 1000 / 60);
  const seconds = Math.floor((time / 1000) % 60);
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

const init = (e, id, idd) => {
  console.log(e, id, idd);

  sceneId = id;
  sceneIdd = idd + "";
  dialogVisible.value = true;
  paymentQrCode.value = e;
  timeoutDuration.value = 60000;
  checkoutPaymentStatus();
  startCountdown();
};
const getPaymentStatus = async () => {
  return new Promise(async (resolve) => {
    await Axios("get", "/pay/pay-status/" + sceneId, false, false, (res) => {
      resolve(res.data.data ? true : false);
      console.log("res.data.data", res.data.data);
    });
  });
};
const checkoutPaymentStatus = () => {
  // 在这里调用API获取支付状态，这只是一个示例，实际使用中你需要替换成真正的API调用
  let randomNumber = null;
  paymentCheckTimer = setInterval(async () => {
    randomNumber = await getPaymentStatus();
    //await getPaymentStatus();
    // console.log("randomNumber", randomNumber);
    // 当 randomNumber 等于2时，模拟支付成功
    // randomNumber = true;
    let status = {
      isSuccess: randomNumber === true,
    };

    if (status.isSuccess) {
      // console.log("支付成功");
      ElMessage.success("支付成功");
      clearInterval(paymentCheckTimer);
      clearInterval(countdownTimer);
      dialogVisible.value = false;

      if (typeof props.CloseQrCode === "function") {
        props.CloseQrCode(sceneIdd);
      }
    }
  }, 1000);
};

const startCountdown = () => {
  countdownTimer = setInterval(() => {
    timeLeft.value -= 1000; // 每秒减去1000毫秒

    if (timeLeft.value <= 0) {
      ElMessage.warning("支付超时");
      clearInterval(countdownTimer);
      clearInterval(paymentCheckTimer);
      dialogVisible.value = false;
    }
  }, 1000);
};

const handleClose = () => {
  dialogVisible.value = false;
  clearInterval(countdownTimer);
  clearInterval(paymentCheckTimer);
};

onUnmounted(() => {
  clearInterval(countdownTimer);
  clearInterval(paymentCheckTimer);
});

defineExpose({ init, handleClose });
</script>

<style lang="less" scoped>
.he {
  width: 230px;
  height: 230px;
}
.center {
  text-align: center;
  & img {
    width: 230px;
    height: 230px;
  }
}
.timer {
  font-size: 2em;
  margin-bottom: 1em;
}
</style>
