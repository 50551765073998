import axios from 'axios'
const concurrentExecution = function (list, limit, asyncHandle) {
    // 递归执行
    let recursion = (arr) => {
        // 执行方法 arr.shift() 取出并移除第一个数据
        return asyncHandle(arr.shift()).then(() => {
            // 数组还未迭代完，递归继续进行迭代
            if (arr.length !== 0) {
                return recursion(arr)
            } else {
                return 'finish'
            }
        })
    }
    // 创建新的并发数组
    let listCopy = [].concat(list)
    // 正在进行的所有并发异步操作
    let asyncList = []
    limit = limit > listCopy.length ? listCopy.length : limit
    while (limit--) {
        asyncList.push(recursion(listCopy))
    }
    // 所有并发异步操作都完成后，本次并发控制迭代完成
    return Promise.all(asyncList)
}
/**
 * 文件分片下载
 * @params fileName {String} 下载文件名称
 * @params pieceSize {Number} 分片大小 默认3MB
 * @params concurrent {Number} 并发数量 默认2
 * @params success {Function} 成功回调函数
 *  @params process {Function} 进度回调函数
 * @params error {Function} 失败回调函数
 */
export default function downByPieces({
    fileName,
    downName,
    pieceSize = 3,
    concurrent = 3,
    success = (res) => {},
    process = (process) => {},
    error = (error) => {},
}) {
    const chunkSize = pieceSize * 1024 * 1024 // 3MB一片
    const chunkList = [] // 分片列表
    const contentList = []
    let total = 0
    const api = axios.create({
        baseURL: 'https://api.biaoshisx.com',
        timeout: 1000 * 60 * 60,
    })
    let lastTime = 0 // 上一次计算时间
    let lastSize = 0 // 上一次计算的文件大小
    const progressHandle = () => {
        const event = { loaded: 0, total: total }
        if (total < chunkSize) {
            event.loaded = chunkList[0].loaded
        } else {
            event.loaded = contentList.length * chunkSize
            const countSize = Math.min(
                contentList.length + concurrent,
                chunkList.length
            )
            for (let i = contentList.length; i < countSize; i++) {
                event.loaded += chunkList[i].loaded
            }
        }
        /*验证数据*/
        if (lastTime == 0) {
            lastTime = new Date().getTime()
            lastSize = event.loaded
            return
        }
        /*计算间隔*/
        var nowTime = new Date().getTime()
        var intervalTime = (nowTime - lastTime) / 1000 // 时间单位为毫秒，需转化为秒
        var intervalSize = event.loaded - lastSize
        /*重新赋值以便于下次计算*/
        lastTime = nowTime
        lastSize = event.loaded
        /*计算速度*/
        var speed = intervalSize / intervalTime
        var bSpeed = speed // 保存以b/s为单位的速度值，方便计算剩余时间
        var units = 'b/s' // 单位名称
        if (speed / 1024 > 1) {
            speed = speed / 1024
            units = 'k/s'
        }
        if (speed / 1024 > 1) {
            speed = speed / 1024
            units = 'M/s'
        }
        /*计算剩余时间*/
        let times = (event.total - event.loaded) / 1
        if (bSpeed) {
            times = (event.total - event.loaded) / bSpeed
        }
        /*计算进度*/
        var progress = (event.loaded / event.total) * 100

        process({ progress, speed, units, times, total, loaded: event.loaded })
    }
    const createDownTask = () => {
        api({
            method: 'head',
            url: '/file/down?filePath=' + fileName,
        }).then((response) => {
            let result = response.headers
            total = Number(result['content-length'])
            const chunkCount = Math.ceil(total / chunkSize) // 总片数
            for (let i = 0; i < chunkCount; i++) {
                let start = 0
                if (i > 0) {
                    start = i * chunkSize
                }
                let end = (i + 1) * chunkSize
                if (end > total) {
                    end = total
                }
                let loaded = 0
                chunkList.push({ start, end, loaded })
            }
            concurrentExecution(chunkList, concurrent, (curItem) => {
                return new Promise((resolve, reject) => {
                    downFile(curItem)
                        .then((res) => {
                            if (contentList.length == chunkCount) {
                                success(res)
                                process({
                                    progress: 100,
                                    speed: 0,
                                    units: 'b/s',
                                    total,
                                    loaded: total,
                                    times: 0,
                                })
                            }
                            resolve()
                        })
                        .catch((e) => {
                            console.log(e)
                            reject(e)
                            error && error(e)
                        })
                })
            }).then((res) => {
                contentList.sort(function(a, b) {
                    return a.start - b.start
                })
                const data= contentList.map(x=>{return x.data})
                const blob = new Blob(data)
                if ('download' in document.createElement('a')) {
                    //支持a标签download的浏览器
                    const link = document.createElement('a') //创建a标签
                    link.download = downName //a标签添加属性
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click() //执行下载
                    URL.revokeObjectURL(link.href) //释放url
                    document.body.removeChild(link) //释放标签
                } else {
                    //其他浏览器
                    navigator.msSaveBlob(blob, downName)
                }
            })
        })
    }
    const downFile = (item) => {
        return new Promise((resolve, reject) => {
            let rande = `${item.start}-${item.end}`
            let headers = {
                range: rande,
            }
            api({
                method: 'get',
                url:
                    '/file/down?filePath=' + fileName + '&fileName=' + downName,
                async: true,
                headers: headers,
                responseType: 'blob',
                onDownloadProgress: function (progressEvent) {
                    item.loaded = progressEvent.loaded
                    progressHandle()
                },
            })
                .then((response) => {
                    if (response.status == 200 || response.status == 206) {
                        contentList.push({start: item.start, data: response.data})
                    }
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
    createDownTask()
}
